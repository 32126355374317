/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import ProductThunk from "../../../../redux/thunk/ProductThunk";
import Toast from "../../../../components/Notification/Toast";
import { message } from 'antd';
import EditProductV2 from "./EditProductV2";
import 'react-quill/dist/quill.snow.css';
import AttributeThunk from "../../../../redux/thunk/AttributeThunk";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ProductDetailWrapper from "./ProductDetailWrapper";
import CommonService from "../../../../utils/CommonService";
import {ATTRIBUTE_GROUP_LIST_REJECTED, FETCH_ATTRIBUTE_REJECTED} from "../../../../redux/types/AttributeActionTypes";
import {
    PRODUCT_ASSET_UPLOAD_FULFILLED,
    PRODUCT_DELETE_FULFILLED,
    PRODUCT_DETAIL_FULFILLED,
    PRODUCT_EDIT_FULFILLED,
    PRODUCT_SKU_VALIDATION_FULFILLED
} from "../../../../redux/types/ProductActionTypes";
import ProductCategories from "./ProductCategories";
import ProductVariant from "./ProductVariant";

dayjs.extend(utc);

const initialState = {
    effectHasRun: false,
    isEditing: false,
    isEditingLabel: false,
    body: [],
    enableSubmit: false,
    filter: [],
    isProductDetailsLoaded: false,
    variants: [],
    reviewChanges: 0,
    activeTab: '1',
    showDialog: false,
    status: '',
    isThumbnailUpdated: false,
    statusToMap: [],
    categories: [],
}

const ProductDeatils = () => {
    const [params] = useSearchParams();
    const {productDetail} = useSelector((state) => state.products);
    const {attributes} = useSelector((state) => state.attributes);
    const dispatch = useDispatch();
    const [editedSKU, setEditedSKU] = useState(productDetail?.sku);
    const [editedLABEL, seteditedLABEL] = useState(productDetail?.product_name);
    const [imageUrl, setImageUrl] = useState(productDetail?.thumbnail || "/images/dummy_product.svg");
    const navigate = useNavigate();
    const [attributeTypeMap,setAttributeTypeMap]=useState(new Map());
    const [states, setStates] = useState(initialState);


    let id = params.get('id');
    let currentPageTab = "attributes"
    if (id) {
        const inputString = id.split('/');
        id = inputString[0]
        currentPageTab = inputString[1]
    }
    let sku = params.get('sku');
    if (sku) {
        const inputString = sku.split('/');
        sku = inputString[0]
        currentPageTab = inputString[1]
    }

    /**
     * Function to get Product Details
     * @returns {Promise<void>}
     */
    const getProductDetails = async () => {
        let payload;
        setImageUrl("/images/dummy_product.svg")
        if (id) {
            payload = await dispatch(ProductThunk.productDetail({ id: id, sku: "" }));
        } else {
            payload = await dispatch(ProductThunk.productDetail({ id: "", sku: sku }));
        }
        if (payload.type === PRODUCT_DETAIL_FULFILLED) {
            if (!(payload.payload.data?.productData)) {
                Toast.errorNotification("No Data Found !")
                return;
            }
            setStates(prevState => ({ ...prevState, isProductDetailsLoaded: true }));
            setStates(prevState => ({ ...prevState, isThumbnailUpdated: false }));
            setImageUrl(payload.payload.data?.productData?.thumbnail || "/images/dummy_product.svg")

        } else {
            Toast.errorNotification(payload?.error?.message);
        }
        setStates(prevState => ({ ...prevState, body: [] }));
        setStates(prevState => ({ ...prevState, isThumbnailUpdated: false }));
    }


    useEffect(() => {
        if (attributes) {
            attributes.map(val =>setAttributeTypeMap(prev => prev.set(val.name, val)));

        }
    }, [attributes]);


    useEffect(() => {
        /**
         * Function to Get Active Tab
         */
        const getActiveTab = () => {
            setStates(prevState => ({ ...prevState, activeTab: currentPageTab }));

        }

        getActiveTab();
    }, [currentPageTab]);

    /**
     * Event Listeners to avoid direct Rerouting
     */
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = 'You have unsaved changes. Are you sure you want to leave?';
            event.returnValue = message;
            return message;
        };

        window.addEventListener('beforeunload', states.enableSubmit ? handleBeforeUnload : null);

        return () => {
            window.removeEventListener('beforeunload', states.enableSubmit ? handleBeforeUnload : null);
        };
    }, [states.enableSubmit]);

    /**
     * SetReview Setting
     */
    useEffect(() => {
        if (states.body.length) {
            setStates(prevState => ({ ...prevState, reviewChanges: states.body.length }));

        }
    }, [states.body])

    /**
     * Inital Calls
     */
    useEffect(() => {
        if (!states.effectHasRun) {
            setImageUrl("/images/dummy_product.svg")
            setStates(prevState => ({ ...prevState, isProductDetailsLoaded: false }));
            setEditedSKU([]);
            seteditedLABEL([]);
            getData()
        } else {
            setStates(prevState => ({ ...prevState, effectHasRun: true }));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, sku]);


    useEffect(() => {
        const KeyToArray = () => {
            let temp = [];
            Object.keys(productDetail).map((key) => {
                const value = productDetail[key];
                if (key !== 'sku' && key !== 'label' && key !== 'updated_at' && key !== 'created_at' && key !== 'id' && key !== 'children' && key !== 'is_variant' && key !== 'status' && key !== 'variation_of') temp.push({
                    'name': key, 'nameToShow': key, 'value': value, type: 'type'
                });
                return key;
            });
            setStates(prevState => ({ ...prevState, status: productDetail?.status }));
            setEditedSKU(productDetail['sku']);
            seteditedLABEL(productDetail['product_name']);
            setStates(prevState => ({ ...prevState, filter: temp }));
            setStates(prevState => ({ ...prevState, isProductDetailsLoaded: false }));
            setStates(prevState => ({ ...prevState, body: [] }));

            if (productDetail?.children) {
                setStates(prevState => ({ ...prevState, variants: [...productDetail.children] }));
            }
            setStates(prevState => ({ ...prevState, isThumbnailUpdated: false }));
        }

        if (productDetail) {
            setEditedSKU(productDetail?.sku);
            seteditedLABEL(productDetail?.product_name);
            KeyToArray();
        }
    }, [productDetail]);

    /**
     * Set Status
     */
    useEffect(() => {
        if ((id || sku) && states.status && productDetail && productDetail.status && states.status !== productDetail.status) {
            handleProductStatusChange();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [states.status])

    /**
     * Function to Change Sku Value
     * @param SkuValue
     */
    const handleSkuChange = (e) => {
        setEditedSKU(e.target.value);
    };

    /**
     * Function to Change Label Change
     * @param LabelValue
     */
    const handleLabelChange = (e) => {
        seteditedLABEL(e.target.value);
    };

    /**
     * Enable Sku to be Edited
     */
    const enableSkuEdit = () => {
        setStates(prevState => ({ ...prevState, editing: !states.isEditing }));

    };
    function isCustomValidJSON(str) {
        if ((str.startsWith('{') && str.endsWith('}')) ||
            (str.startsWith('[') && str.endsWith(']'))) {
            try {
                const parsed = JSON.parse(str);

                if (typeof parsed === 'object' && parsed !== null) {
                    return true;
                }
            } catch (e) {
                return false;
            }
        }
        return false;
    }

    /**
     * Function to Submit Edited Body
     * @returns {Promise<void>}
     */
    const handleSubmit = async () => {

        const categoriesPath = states?.categories?.map(val => val.path);
        // console.log("categoriesPath", categoriesPath)

        // return

        const payload = await dispatch(ProductThunk.productEdit({
            id: productDetail?.id,
            sku: productDetail?.sku,
            attributesToEdit: [
                ...states.body.map(val => {
                    let temp = { ...val,value:Array.isArray(val.value)?[...val.value.map(v=>isCustomValidJSON(v)?JSON.parse(v):v)]:[isCustomValidJSON(val.value)?JSON.parse(val.value) : val.value] };

                    temp.type = attributeTypeMap.get(val.name)?.type;
                    return temp;
                }),
                {
                    name: "parent_sku",
                    value: productDetail.parent_sku,
                    type: 'text'
                },

                // Conditionally add the categories if any are selected
                ...(states.categories.length > 0 ? [{
                    name: "Default Categories",
                    value: categoriesPath, // This will be an array of selected category objects
                    type: 'multiselect'
                }] : [])
            ]
        }));

        if (payload.type === PRODUCT_EDIT_FULFILLED) {
            Toast.successNotification("Product Updated Successfully");
            
            getProductDetails();
            setStates(prevState => ({ ...prevState, enableSubmit: false }));
            setStates(prevState => ({ ...prevState, reviewChanges: 0 }));
        } else {
            Toast.errorNotification(payload.error.message);
        }

    }


    /**
     * Function to convert KeyValue pair to array object
     * @constructor
     */

    const getAttributeData = async () => {
        let attributepayload = await dispatch(AttributeThunk.fetchAttributes())
        if (attributepayload.type === FETCH_ATTRIBUTE_REJECTED) {
            Toast.errorNotification(attributepayload?.error?.message);
        } else {
            setStates(prevState => ({ ...prevState, statusToMap: attributepayload.payload.attributes.find(val => val.name === 'status')?.selectOption }));

        }
    }
    const getAttributeGroupData = async () => {
        let AttributeGroupListPayload = await dispatch(AttributeThunk.attrbuteGroupList());
        if (AttributeGroupListPayload.type === ATTRIBUTE_GROUP_LIST_REJECTED) {
            Toast.errorNotification(AttributeGroupListPayload?.error?.message);
        }
    }

    /**
     * Status Tabs
     // * @type {[{label: React.JSX.Element, key: string},{label: React.JSX.Element, key: string},{label: React.JSX.Element, key: string}]}
     */
    let statusItems = []

    statusItems = states.statusToMap?.map((val) => {
        return {
            label: val, key: val, onClick: () => {
                setStates(prevState => ({ ...prevState, status: val }));
            }
        }
    });

    // To-Do
    // const getProductDetailsCall=async()=>{
    //     let payload;
    //     if (id) {
    //         payload = await dispatch(ProductThunk.productDetail({id: id, sku: ""}));
    //     } else {
    //         payload = await dispatch(ProductThunk.productDetail({id: "", sku: sku}));
    //     }
    //     if (payload.type === PRODUCT_DETAIL_FULFILLED) {
    //         if (!(payload.payload.data?.productData)) {
    //             Toast.errorNotification("No Data Found !")
    //             return;
    //         }
    //     } else {
    //         Toast.errorNotification(payload?.error?.message);
    //     };
    // }

    /**
     * Function to Get Data of Attribute AttributeGroups and ProductDetails
     * @returns {Promise<void>}
     */
    const getData = async () => {
        try {
            let temp = [getAttributeData(),
            getAttributeGroupData(),
            getProductDetails()

            ];
            Promise.all(temp)
        } catch (err) {
            Toast.errorNotification(err.message);
        }
    }

    /**
     * Function to Delete Product
     * @returns {Promise<void>}
     */
    const handleDeleteProduct = async () => {
        const payload = await dispatch(ProductThunk.productDelete(productDetail.id));
        if (payload.type === PRODUCT_DELETE_FULFILLED) {
            Toast.successNotification("Product Deleted Successfully");
            navigate("/product/list")
        } else {
            Toast.errorNotification(payload?.error?.message);
        }
    }

    // const items = [
    //     {
    //         key: '3', label: (<Popconfirm
    //             title="Are you sure you want to delete?"
    //             onConfirm={handleDeleteProduct}
    //             okText="Yes"
    //             cancelText="No"
    //         >
    //             <Tooltip title="Delete">
    //                 <span className="icon">
    //                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    //                         <path
    //                             d="M4.66675 14C4.30008 14 3.98619 13.8694 3.72508 13.6083C3.46397 13.3472 3.33341 13.0333 3.33341 12.6667V4H2.66675V2.66667H6.00008V2H10.0001V2.66667H13.3334V4H12.6667V12.6667C12.6667 13.0333 12.5362 13.3472 12.2751 13.6083C12.014 13.8694 11.7001 14 11.3334 14H4.66675ZM11.3334 4H4.66675V12.6667H11.3334V4ZM6.00008 11.3333H7.33342V5.33333H6.00008V11.3333ZM8.66675 11.3333H10.0001V5.33333H8.66675V11.3333Z"
    //                             fill="#423F3F"/>
    //                     </svg>
    //                 </span>
    //                 Delete
    //             </Tooltip>
    //         </Popconfirm>),
    //     },];

    const tabs = [{
        key: 'attributes', label: 'Attributes', children: <EditProductV2

            // body={states.body}
            // setBody={setBody}
            // myAttributes={states.filter}
            // enableSubmit={states.enableSubmit}
            // setEnableSubmit={setEnableSubmit}
            handleSubmit={handleSubmit}
            // isProductDetailsLoaded={states.isProductDetailsLoaded}
            // setIsProductDetailsLoaded={setIsProductDetailsLoaded}
            isAssetPage={false}
            getData={getProductDetails}
            // tab={states.activeTab}
            attributeTypeMap={attributeTypeMap}

            // handleSubmit={states.handleSubmit}
            // isAssetPage={false}
            // getData={getProductDetails}
            // attributeTypeMap={attributeTypeMap}
            states= {states}
            setStates= {setStates}

        />,
    }, {
        key: 'assets', label: 'Assets', children: <div className={"attribute_detail_card"}>
            <EditProductV2
                isAssetPage={true}
                // body={states.body}
                // setBody={setBody}
                // myAttributes={states.filter}
                // enableSubmit={states.enableSubmit}
                // setEnableSubmit={setEnableSubmit}
                handleSubmit={handleSubmit}
                // isProductDetailsLoaded={states.isProductDetailsLoaded}
                // setIsProductDetailsLoaded={setIsProductDetailsLoaded}
                getData={getProductDetails}
                attributeTypeMap={attributeTypeMap}

                // isAssetPage={true}
                // handleSubmit={handleSubmit}
                // getData={getProductDetails}
                // attributeTypeMap={attributeTypeMap}
                states= {states}
                setStates= {setStates}

            />,
        </div>,
    },
        {
            key: 'variations', label: 'Variations', children: productDetail?.variation_of ? () => {
                Toast.errorNotification("Product is Already a variant!");
                navigate('/product/details?id=' + productDetail.id + '/attributes');

            } : <div className={"attribute_detail_card"}>
                <ProductVariant variants={states.variants}
                                present={productDetail}
                                getProductDetails={getProductDetails}
                /></div>,
        },
        {
            key: 'categories',
            label: 'Categories',
            children: <ProductCategories
                handleSubmit={handleSubmit}
                getData={getProductDetails}
                setStates={setStates}
            />,
            onClick: () => navigate('/product/details?id=' + productDetail.id + '/categories'),
        },

    ];

    const columns = tabs.filter(val => val.key !== 'variations');

    /**
     * Change SKU submit
     * @returns {Promise<void>}
     */
    const handleSkuSubmit = async () => {
        setStates(prevState => ({ ...prevState, editing: false }));
        if (!editedSKU) {
            Toast.errorNotification("SKU is Required");
            setEditedSKU(productDetail.sku)
            return;
        }
        if (editedSKU === productDetail.sku) {
            return;
        }
        const payloadSku = await dispatch(ProductThunk.productSkuValidation({
            sku: editedSKU, excludeProductId: productDetail.id
        }));
        if (payloadSku.type === PRODUCT_SKU_VALIDATION_FULFILLED) {
            if (!payloadSku.payload.data.isAvailable) {
                Toast.errorNotification("SKU Already Exist");
                setEditedSKU(productDetail.sku)
                return;
            }
        }
        dispatch(ProductThunk.productEdit({
            id: productDetail.id,
            sku: productDetail.sku,
            attributesToEdit: [{ "name": "sku", 'value': editedSKU, "type": 'text' }]

        }));
        getProductDetails();
    }

    /**
     * Change Label submit
     */
    const handleLabelSubmit = () => {
        setStates(prevState => ({ ...prevState, editingLabel: false }));

        if (!editedLABEL) {
            Toast.errorNotification("Product Name is Required !!");
            seteditedLABEL(productDetail?.product_name)
            return;
        }
        dispatch(ProductThunk.productEdit({
            sku: productDetail.sku,
            id: productDetail.id,
            attributesToEdit: [{ "name": "product_name", 'value': editedLABEL, "type": 'text' }]

        }));
        getProductDetails();

    }

    /**
     * Funtion to set Label editable
     */
    const handleLabelEdit = () => {
        setStates(prevState => ({ ...prevState, editingLabel: !states.isEditingLabel }));

    }


    /**
     * Change Status Change
     * @returns {Promise<void>}
     */
    const handleProductStatusChange = async () => {

        const body = {
            id: productDetail.id, sku: productDetail.sku, attributesToEdit: [{
                name: "status", value: states.status, 'type': 'dropdown'

            }]

        }
        const payload = await dispatch(ProductThunk.productEdit(body));
        if (payload.type === PRODUCT_EDIT_FULFILLED) {
            // Toast.successNotification("Product Status Updated Successfully");
            getProductDetails();
            // getData();
        } else {
            // Toast.errorNotification(payload.error.message);
        }


    }

    /**
     * Navigate to Another Page
     */
    const handleNavigateToAnotherPage = () => {
        if (states.enableSubmit) {
            // Show the confirmation dialog
            setStates(prevState => ({ ...prevState, showDialog: true }));

        } else {
            if (productDetail?.variation_of) {
                navigate(-1);
            } else {
                navigate('/product/list')
            }


        }
    };

    /**
     * Discard Changes
     */
    const handleConfirmDiscardChanges = () => {
        // Perform any additional actions if needed
        // ...

        // Close the confirmation dialog
        setStates(prevState => ({ ...prevState, showDialog: false }));


        // Navigate to another page
        if (productDetail?.variation_of) {
            navigate(-1);
        } else {
            navigate('/product/list')
        }
    };

    /**
     * Keep Changes
     */
    const handleCancelDiscardChanges = () => {
        // Close the confirmation dialog
        setStates(prevState => ({ ...prevState, showDialog: false }));
    };

    /**
     * Function to Upload Thumbnail
     * @param Fileinfo
     */
    const handleUpload = async (info) => {
        // setIsThumbnailUpdated(true);
        setStates(prevState => ({ ...prevState, isThumbnailUpdated: true }));
        const formData = new FormData();

        formData.append('attributeName', 'thumbnail');
        formData.append('productId', productDetail.id);
        // Append each file to the FormData
        info.fileList.forEach(file => {
            formData.append('file', file.originFileObj);
        });
        const payload = await dispatch(ProductThunk.productAssetUpload(formData));
        if (payload.type === PRODUCT_ASSET_UPLOAD_FULFILLED) {
            getProductDetails();
            info.fileList = [];
            setImageUrl("")
            message.success(`Thumbnail Updated Successfully`);
        } else {
            message.error(`Thumbnail Updation Failed`);
        }

    };

    /** Function to Delete Thumbnail */
    const handleThumbnailDelete = async () => {
        setStates(prevState => ({ ...prevState, isThumbnailUpdated: true }));

        CommonService.handleAssetDelete(dispatch, productDetail.id, 'thumbnail', productDetail.thumbnail, getProductDetails);
    };

    const props = {
        params: params,
        productDetail: productDetail,
        dispatch: dispatch,
        getProductDetails: getProductDetails,
        handleThumbnailDelete: handleThumbnailDelete,
        handleUpload: handleUpload,
        handleNavigateToAnotherPage: handleNavigateToAnotherPage,
        handleConfirmDiscardChanges: handleConfirmDiscardChanges,
        handleCancelDiscardChanges: handleCancelDiscardChanges,
        handleLabelEdit: handleLabelEdit,
        handleLabelSubmit: handleLabelSubmit,
        handleSkuSubmit: handleSkuSubmit,
        enableSkuEdit: enableSkuEdit,
        handleProductStatusChange: handleProductStatusChange,
        handleDeleteProduct: handleDeleteProduct,
        editedSKU: editedSKU,
        setEditedSKU: setEditedSKU,
        editedLABEL: editedLABEL,
        seteditedLABEL: seteditedLABEL,
        imageUrl: imageUrl,
        setImageUrl: setImageUrl,
        navigate: navigate,    
        getAttributeData: getAttributeData,
        getAttributeGroupData: getAttributeGroupData,
        getData: getData,
        handleSkuChange: handleSkuChange,
        handleLabelChange: handleLabelChange,
        handleSubmit: handleSubmit,
        tabs: tabs,
        columns: columns,
        statusItems: statusItems,
        sku: sku,
        states: states,
        setStates: setStates,

    }

    return (
        <ProductDetailWrapper {...props} />

    )
}


export default ProductDeatils;
