import React from 'react';
import {Modal, Dropdown, Spin, Table} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import EditColumns from "../../../views/EditColumns";
import FilterModal from "../../../../components/Modal/FilterModal";
import FilterWrapper from "./FilterWrapper";

// const {Option} = Select;

const ProductListWrapper = (props) => {

    const filterValueProps={
        handleOpenFilterModal:props.handleOpenFilterModal,
        handleApplyFilter: props.handleApplyFilter,
        onSearch: props.onSearch,
        handleSearchAttribute: props.handleSearchAttribute,
        handleSetFilter: props.handleSetFilter,
        clearFilterData: props.clearFilterData,
        attributes: props.attributes,
        onSearchEdit: props.onSearchEdit,
        deleteFilter: props.deleteFilter,
        filterViewName: props.filterViewName,
        clearSelectedFilter: props.clearSelectedFilter,
        states: props.states,
        setStates: props.setStates
    }
    return (
        <div className="product_list_wrapper">
            <Modal
                title="Edit Columns"
                open={props.editColumnsVisible}
                closable={false}      // Set closable to false to hide the close button
                maskClosable={false}
                footer={null}
                className={"custom_modal edit_columns_modal"}
            >
                <EditColumns
                    visible={props.editColumnsVisible}
                    setVisible={props.setEditColumnsVisible}
                    onclose={props.handleEditColumnsClose}
                    singleProduct={props.singleProduct}
                />
            </Modal>
            <div className="subheader">
                <div className="left_btn_wrap">

                    <Dropdown overlayClassName={"create_product_dropdown"} menu={{ items: props.items }}
                              trigger={['click']}>
                        <span className="primary_btn" onClick={(e) => e.preventDefault()}>
                            <i className={"icon"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                     fill="none">
                                    <path
                                        d="M2.91669 6.99999H7.00002M7.00002 6.99999H11.0834M7.00002 6.99999V2.91666M7.00002 6.99999V11.0833"
                                        stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </i>Create Product
                        </span>
                    </Dropdown>
                    {
                        !!props.selectedRowKeystoDelete.length && <button
                            style={{
                                cursor: 'pointer',
                                marginLeft: '10px',
                            }}
                            className="secondary_icon_btn" onClick={props.handleBulkDeleteProducts}>
                            <DeleteOutlined/>
                            Delete {props.selectedRowKeystoDelete.length}
                        </button>
                    }

                    <button
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={props.handleEditColumnsClick}
                        className="secondary_icon_btn">
                        <i className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                 fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M0 3C0 1.34315 1.34315 0 3 0H11C12.6569 0 14 1.34315 14 3V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3ZM7.75 1.5H11C11.8284 1.5 12.5 2.17157 12.5 3V11C12.5 11.8284 11.8284 12.5 11 12.5H7.75V1.5ZM6.25 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V11C1.5 11.8284 2.17157 12.5 3 12.5H6.25V1.5Z"
                                      fill="#667085"/>
                            </svg>
                        </i>
                        Edit Columns
                    </button>
                    <button
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={props.exportProductsToCsv}
                        className="secondary_icon_btn">
                        <i className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                 fill="none">
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M0 3C0 1.34315 1.34315 0 3 0H11C12.6569 0 14 1.34315 14 3V11C14 12.6569 12.6569 14 11 14H3C1.34315 14 0 12.6569 0 11V3ZM7.75 1.5H11C11.8284 1.5 12.5 2.17157 12.5 3V11C12.5 11.8284 11.8284 12.5 11 12.5H7.75V1.5ZM6.25 1.5H3C2.17157 1.5 1.5 2.17157 1.5 3V11C1.5 11.8284 2.17157 12.5 3 12.5H6.25V1.5Z"
                                      fill="#667085"/>
                            </svg>
                        </i>
                        Export Products
                    </button>
                </div>
                <div className="right_btn_wrap">
                    <div className="search_box border_ui">
                        <input type="text"
                               placeholder="Search"
                               onChange={props.handleSearch}
                        />
                        <span className="search_icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21"
                                 fill="none">
                              <path
                                  d="M18.375 18.375L13.125 13.125M2.625 8.75C2.625 9.55435 2.78343 10.3508 3.09124 11.0939C3.39905 11.8371 3.85021 12.5123 4.41897 13.081C4.98773 13.6498 5.66294 14.101 6.40606 14.4088C7.14918 14.7166 7.94565 14.875 8.75 14.875C9.55435 14.875 10.3508 14.7166 11.0939 14.4088C11.8371 14.101 12.5123 13.6498 13.081 13.081C13.6498 12.5123 14.101 11.8371 14.4088 11.0939C14.7166 10.3508 14.875 9.55435 14.875 8.75C14.875 7.94565 14.7166 7.14918 14.4088 6.40606C14.101 5.66294 13.6498 4.98773 13.081 4.41897C12.5123 3.85021 11.8371 3.39905 11.0939 3.09124C10.3508 2.78343 9.55435 2.625 8.75 2.625C7.94565 2.625 7.14918 2.78343 6.40606 3.09124C5.66294 3.39905 4.98773 3.85021 4.41897 4.41897C3.85021 4.98773 3.39905 5.66294 3.09124 6.40606C2.78343 7.14918 2.625 7.94565 2.625 8.75Z"
                                  stroke="#667085" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                    </div>

                    <div className="filter_wrapper">
                        <button id={"filter_btn"}
                                className={props.states.selectedAttributeFilters && props.states.selectedAttributeFilters.length ? "filter_btn active" : "filter_btn"}
                                type="button" onClick={props.toggleDivVisibility}>
                            <span className="icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                     fill="none">
                                  <path
                                      d="M10.8333 6.66667C10.8333 7.1269 11.2064 7.5 11.6667 7.5C12.1269 7.5 12.5 7.1269 12.5 6.66667V5.83333H16.6667C17.1269 5.83333 17.5 5.46024 17.5 5C17.5 4.53976 17.1269 4.16667 16.6667 4.16667H12.5V3.33333C12.5 2.8731 12.1269 2.5 11.6667 2.5C11.2064 2.5 10.8333 2.8731 10.8333 3.33333V6.66667Z"
                                      fill="#667085"/>
                                  <path
                                      d="M2.5 10C2.5 9.53976 2.8731 9.16667 3.33333 9.16667H4.58333C4.81345 9.16667 5 9.35321 5 9.58333V10.4167C5 10.6468 4.81345 10.8333 4.58333 10.8333H3.33333C2.8731 10.8333 2.5 10.4602 2.5 10Z"
                                      fill="#667085"/>
                                  <path
                                      d="M7.5 7.5C7.03976 7.5 6.66667 7.8731 6.66667 8.33333V11.6667C6.66667 12.1269 7.03976 12.5 7.5 12.5C7.96024 12.5 8.33333 12.1269 8.33333 11.6667V10.8333H16.6667C17.1269 10.8333 17.5 10.4602 17.5 10C17.5 9.53976 17.1269 9.16667 16.6667 9.16667H8.33333V8.33333C8.33333 7.8731 7.96024 7.5 7.5 7.5Z"
                                      fill="#667085"/>
                                  <path
                                      d="M2.5 5C2.5 4.53976 2.8731 4.16667 3.33333 4.16667H8.75C8.98012 4.16667 9.16667 4.35321 9.16667 4.58333V5.41667C9.16667 5.64679 8.98012 5.83333 8.75 5.83333H3.33333C2.8731 5.83333 2.5 5.46024 2.5 5Z"
                                      fill="#667085"/>
                                  <path
                                      d="M12.5 13.3333C12.5 12.8731 12.8731 12.5 13.3333 12.5C13.7936 12.5 14.1667 12.8731 14.1667 13.3333V14.1667H16.6667C17.1269 14.1667 17.5 14.5398 17.5 15C17.5 15.4602 17.1269 15.8333 16.6667 15.8333H14.1667V16.6667C14.1667 17.1269 13.7936 17.5 13.3333 17.5C12.8731 17.5 12.5 17.1269 12.5 16.6667V13.3333Z"
                                      fill="#667085"/>
                                  <path
                                      d="M2.5 15C2.5 14.5398 2.8731 14.1667 3.33333 14.1667H10.4167C10.6468 14.1667 10.8333 14.3532 10.8333 14.5833V15.4167C10.8333 15.6468 10.6468 15.8333 10.4167 15.8333H3.33333C2.8731 15.8333 2.5 15.4602 2.5 15Z"
                                      fill="#667085"/>
                                </svg>
                            </span>
                            Filter
                        </button>
                    </div>
                </div>
            </div>

            <div className="table_wrapper">
                <div style={{display:"flex",alignItems:"center",width:props.isDivVisible?'calc(100vw - 310px)':'100vw',justifyContent:'space-between',}}>
                    <div className="total_result">
                        <strong className={"total_count"}>Total results:</strong> <span
                        className={"count_text"}>{props.count} {props.count > 1 ? "Products" : "Product"} </span>
                        {/*<span className={"parent_count"}>({parentCount} {parentCount > 1 ? "parents" : "parent"} and {variantsCount} {variantsCount > 1 ? "variations" : "variation"})</span>*/}
                    </div>
                    {/*<div style={{marginRight:'10px'}}>*/}
                    {/*        <span style={{marginRight:'10px'}}>Default View</span>*/}
                    {/*        <Switch style={{backgroundColor:defaultView?'#011952':'#D9D9D9',borderColor:defaultView?'#011952':'#D9D9D9'}} disabled={isPaginationProductLoading} onChange={()=>toogleDefaultView()} value={defaultView}>*/}
                    {/*        </Switch>*/}

                    {/*</div>*/}
                </div>

                <div className={props.isDivVisible ? 'product_list_table active' : 'product_list_table'}>
                    <div className="custom_table_ui">
                        <Spin spinning={props.states.isPaginationProductLoading || props.isLoading || props.isCsvImported}>
                            {Array.isArray(props.products) ? (
                                (props.states.searchedProductList && props.states.searchedProductList.length) ? <Table
                                        showHeader={!!(props.states.searchedProductList && props.states.searchedProductList.length)}
                                        rowSelection={{
                                            type: 'checkbox',
                                            columnWidth: "60px",

                                            ...props.rowSelection
                                        }}
                                        scroll={{
                                            x: 1600,
                                        }}
                                        sticky={{
                                            offsetHeader: 0,
                                        }}
                                        onSelectAll={(selected, selectedRows, changeRows) => {

                                        }}
                                        fixed={props.fixedTop ? 'top' : 'bottom'}
                                        dataSource={props.states.searchedProductList}
                                        columns={props.columns}
                                        rowKey="id"
                                        pagination={{
                                            ...props.tableParams.pagination,
                                            current: props.skip !== 1 ? (props.skip / props.limit) + 1 : 1,
                                            total: props.parentCount,
                                            showSizeChanger: true,
                                            rootClassName: "single_select",
                                            pageSizeOptions: ["25", "50", "75", "100"],
                                        }}
                                        expandIcon={props.CustomExpandIcon}
                                        onChange={props.handleTableChange}
                                    /> :
                                    <div className={"empty_table_ui"}>
                                        <figure><img src={"/images/newproduct_placeholder.svg"} alt={""}/></figure>
                                        <div className={"empty_message"}>
                                            <label>No Product : Upload your Product list.</label>
                                            <p>Prepare your order page with uploading or Adding Product.</p>
                                        </div>
                                    </div>
                            ) : (
                                <p>Error: The data is not in the expected format.</p>
                            )}
                        </Spin>
                    </div>
                    <FilterWrapper {...filterValueProps}/>
                </div>
            </div>

            <FilterModal
                handleClose={props.handleFilterModalClose}
                handleSaveFilter={props.states.filterModalType === 'list' ? props.handleLoadFilter : props.handleSaveFilter}
                states={props.states}
                setStates={props.setStates}
            />


        </div>
    );
};

export default ProductListWrapper;
