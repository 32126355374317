/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Modal, Progress, Row, Select, Spin, Switch, Table, Tag, Typography} from 'antd';
import {useDispatch, useSelector} from 'react-redux';
import zlib from 'react-zlib-js'
import AttributeThunk from "../../../redux/thunk/AttributeThunk";
import ProductThunk from "../../../redux/thunk/ProductThunk";
import {DownloadOutlined, LoadingOutlined, PaperClipOutlined, UploadOutlined} from "@ant-design/icons";
import Toast from "../../../components/Notification/Toast";
import {useNavigate} from "react-router-dom";
import {openDB} from 'idb';

import {IMPORT_PRODUCT_CSV_FULFILLED} from "../../../redux/types/ProductActionTypes";
import Papa from 'papaparse';
import {FETCH_ATTRIBUTE_REJECTED} from "../../../redux/types/AttributeActionTypes";

const {Title} = Typography;

const {Option} = Select;
const TestCSV = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [preferences, setPreferences] = useState([]);
    const [toogle, setToogle] = useState(false);
    const [filteredData, setFilteredData] = useState(data);
    const [constPreferences, setConstPreferences] = useState([]);
    const [changedPreferances, setChangedPreferances] = useState(new Set());
    const fileRef = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFileName, setUploadedFileName] = useState("");
    const [fileSizeInMB, setFileSizeInMB] = useState(0);
    const [call, setCall] = useState(0);
    const [lines, setLines] = useState([]);
    const [overAllLoading, setOverAllLoading] = useState(false);
    const [isRowsLoaded, setIsRowsLoaded] = useState(true);
    const [skuIndex, setSkuIndex] = useState(null)
    const [headers, setHeaders] = useState([]);
    const [createNewToggle, setCreateNewToggle] = useState(false)
    const [requiredToggle, setRequiredToggle] = useState(false)
    const [dataFromDB, setDataFromDB] = useState(true);
    const {attributes} = useSelector(state => state.attributes);
    const [checked,setChecked] = useState(false);
    const [initialProductLoad,setInitialProductLoad] = useState(true);
    // SKU   (Required)
    // Product Name ( Required)
    // Taxonomy/Categories
    // Brand Name ( Required)
    // Parent SKU ( Required)
    const [mustHaveAttributes,setMustHaveAttributes] = useState(['SKU', 'Product Name', 'Brand Name', 'Taxonomy/Categories','Price' , 'Default Categories']);


    const [requiredAttributes, setRequiredAttributes] = useState([]);

    const showModal = (record) => {
        setSelectedRecord(record);
        setModalVisible(true);
    };

    const checkData = (data1) => {
        return data.toString().toLowerCase().includes(data1.toString().toLowerCase());
    };
    const handleVariantSwitchChange = () => {
        if (preferences && preferences.length > 0) {
            const ParentSKU = {
                isRequired: true,
                isSelected: false,
                name: "Parent SKU",
                preference: "required",
                type: "text"
            };

            let newPref;
            if (!checked) {
                // Remove "Parent SKU" from must-have attributes and preferences
                const newMustHaves = mustHaveAttributes.filter(attribute => attribute !== "Parent SKU");
                setMustHaveAttributes(newMustHaves);
                newPref = preferences.filter((element) => element.name !== "Parent SKU");
            } else {
                // Add "Parent SKU" to must-have attributes
                setMustHaveAttributes([...mustHaveAttributes, "Parent SKU"]);

                // Find index of "Brand Name" in preferences
                const brandIndex = preferences.findIndex((element) => element.name === "Brand Name");

                // Check if "Parent SKU" already exists in preferences
                const isParentSKUExist = preferences.some((element) => element.name === "Parent SKU");

                if (!isParentSKUExist) {
                    if (brandIndex !== -1) {
                        // Insert "Parent SKU" after "Brand Name" if it exists
                        newPref = [
                            ...preferences.slice(0, brandIndex + 1),
                            ParentSKU,
                            ...preferences.slice(brandIndex + 1)
                        ];
                    } else {
                        // Append "Parent SKU" if "Brand Name" is not found
                        newPref = [...preferences, ParentSKU];
                    }
                } else {
                    // If "Parent SKU" exists, just use the current preferences
                    newPref = preferences;
                }
            }
            setPreferences([...newPref]);
        }
    };


    useEffect(()=>{
       if(preferences && preferences.length > 0){
           if(initialProductLoad){
               handleVariantSwitchChange()
           }
           setInitialProductLoad(false)
       }
    },[checked,preferences])
    useEffect(()=>{

               handleVariantSwitchChange()

    },[checked])

    useEffect(() => {
        // if(!attributeList.length) {
        fetchData();
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (preferences && preferences.length) {
            saveDataToIndexedDB({preferences, headers, lines, uploadedFileName: uploadedFileName});
        }
    }, [preferences]);

    const handleUpload = async () => {
        try {
            setOverAllLoading(true);
            const skuHeaderIndex = headers.findIndex(header => header.toLowerCase().includes("sku"));
            // Give error if csv rows are greater than 20000
            if (lines.length >= 20000) {
                Toast.errorNotification("CSV has more than 20000 rows", 4.5)
                setHeaders([]);
                setLines([]);
                setLines([]);
                setUploadProgress(0);
                setUploadedFileName("");
                setFileSizeInMB(0);
                setCall(0);
                setPreferences([]);
                setConstPreferences([]);
                setChangedPreferances(new Set());
                setOverAllLoading(false);
                fileRef.current.value = null;
                return
            }
            // Check if SKU header exists
            if (skuHeaderIndex !== -1) {
                // SKU header exists, you can use skuHeaderIndex as needed
                setSkuIndex(skuHeaderIndex)
                const skuColumnIdx = skuHeaderIndex; // Update this if needed
                const emptySkuIndices = [];
                const emptyRowsIndices = [];

                const columnValues = await Promise.all(
                    lines.map(async (line, index) => {
                        if (line && JSON.stringify(line) !== JSON.stringify([null])) {

                            const skuValue = line[skuColumnIdx];

                            // Check if SKU value is empty
                            if (!skuValue) {
                                emptySkuIndices.push(index + 1); // Adding 1 to convert from zero-based index to one-based index
                            }

                            return skuValue;
                        }
                        if (JSON.stringify(line) === JSON.stringify([null])) {
                            emptyRowsIndices.push(index + 1);
                        }
                    })
                );
                // check for index  null rows in csv

                if (emptySkuIndices.length > 0) {
                    Toast.errorNotification(`There are empty SKUs in CSV on Rows \n ${emptySkuIndices.join(",")} `, 4.5)
                    setHeaders([]);
                    setLines([]);
                    setLines([]);
                    setUploadProgress(0);
                    setUploadedFileName("");
                    setFileSizeInMB(0);
                    setCall(0);
                    setPreferences([]);
                    setConstPreferences([]);
                    setChangedPreferances(new Set());
                    setOverAllLoading(false);
                    fileRef.current.value = null;
                    return
                }
            } else {
                // SKU header not found
                Toast.errorNotification("Unable to Find sku Columns in CSV", 4.5)
                setHeaders([]);
                setLines([]);
                setLines([]);
                setUploadProgress(0);
                setUploadedFileName("");
                setFileSizeInMB(0);
                setCall(0);
                setPreferences([]);
                setConstPreferences([]);
                setChangedPreferances(new Set());
                setOverAllLoading(false);
                fileRef.current.value = null;
                return
                // Handle the case where SKU header is not present in the file
            }

            setLines(lines);
            fetchData();
            setIsLoaded(false);
            Toast.successNotification(uploadedFileName + " Uploaded Successfully", 1.5)
            setOverAllLoading(false);
        } catch (error) {

            fileRef.current.value = null;
            setOverAllLoading(false);
            console.error('Error converting CSV to JSON:', error);
        }
    };
    const fetchData = async () => {
        try {
            setCall(1);
            const response = await dispatch(AttributeThunk.fetchAttributes());

            if (!response || !response.payload) {
                setPreferences([]);
                setConstPreferences([]);
                return;
            }

            const attributes = response.payload.attributes;
            // check if attributes contains all required attributes
            const tempReqAttributes = attributes.filter(attribute => mustHaveAttributes.includes(attribute.nameToShow))


            const attributeNames = attributes
                .filter(attribute => !mustHaveAttributes.includes(attribute.nameToShow))
                .map(attribute => ({
                    name: attribute.nameToShow,
                    type: attribute.type,
                }));

            setRequiredAttributes(tempReqAttributes);
            setData(attributeNames);
            setFilteredData(attributeNames);

            const updatedPreferences = headers
                .filter(header => !mustHaveAttributes.includes(header))
                .map(header => {
                    const matchAttribute = attributeNames.find(
                        attribute => attribute.name.toLowerCase() === header.toLowerCase()
                    );

                    return {
                        name: header,
                        type: matchAttribute ? matchAttribute.type : '',
                        preference: matchAttribute ? 'matchWithExisting' : 'createNew',
                        matchAttribute: matchAttribute,
                    };
                });

            const requiredAttributesData = requiredAttributes.map(attribute => ({
                name: attribute.nameToShow,
                type: attribute.type,
                preference: 'required',
                isRequired: true,
                isSelected: false,
            }));
            let attributesFound = []
            const requiredAttributesData1 = requiredAttributesData.map(attribute2 => {
                const matchAttribute = headers.find(
                    header => {
                        // here match cases for small snake case and camel case
                        return header.toLowerCase().includes(attribute2.name.toLowerCase().replace(/ /g, '_')) || header.toLowerCase().includes(attribute2.name.toLowerCase().replace(/ /g, '') || header.toLowerCase().includes(attribute2.name.toLowerCase().replace(/ /g, '_').replace(/_/g, '')))
                    }
                );
                if (matchAttribute) {
                    attributesFound.push(attribute2)
                    attribute2.isSelected = true;
                    attribute2.preference = 'selected';
                    attribute2.matchColumn = matchAttribute;

                }
                return attribute2;
            });
            // here match cases for small snake case and camel case
            // check for duplicate preferences based on small snake case and camel case and lowercase
            // filter attribute found from updated preferences
            const ExcludedFoundAttributes = updatedPreferences.filter(
                //filter on basis of name
                (val) => !attributesFound.find((val2) => val2.matchColumn.toLowerCase() === val.name.toLowerCase())
            )
            // filter attribute found from required attributes

            // Combine both sets of preferences using Set to avoid duplicates
            if (!dataFromDB) {
                const combinedAttributes = [...requiredAttributesData1, ...ExcludedFoundAttributes];

                const uniqueAttributes = combinedAttributes.reduce((acc, current) => {
                    if (!acc.some(item => item.name === current.name)) {
                        acc.push(current);
                    }
                    return acc;
                }, []);

                setPreferences([...uniqueAttributes]);
            }

            const tempData = updatedPreferences.filter(val => val.preference === 'createNew');
            setConstPreferences(tempData);
            setIsLoaded(false);
            if (!call) setCall(call + 1);
        } catch (error) {
            console.error("Error fetching attributes :", error);
        }
    };


    useEffect(() => {

        setOverAllLoading(true);

        if (headers.length && (!dataFromDB)) {
            handleUpload();
            setIsRowsLoaded(false)

        }
        setOverAllLoading(false)
        setIsLoaded(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headers, dataFromDB])
    const handleSendData = async () => {
        try {
            setOverAllLoading(true);
            // return error if all required attributes not selected validation
            const isAllSelected = preferences.filter((val) => val.isSelected);

            if (isAllSelected.length !== mustHaveAttributes.length) {
                Toast.errorNotification("Please select all required attributes", 4.5)
                setOverAllLoading(false);
                return
            }
            const csvHeaderRows = preferences.map((val) => {
                // Create New Case
                const modifiedVal = {...val};

                // Create New Case
                if (modifiedVal.preference === "createNew") {
                    modifiedVal.matchColumn = modifiedVal.name;

                }

                if (modifiedVal.preference === 'ignore') {
                    modifiedVal.matchColumn = 'ignore';

                }
                if (modifiedVal.preference === 'matchWithExisting') {
                    if (!modifiedVal.matchColumn) {
                        modifiedVal.matchColumn = modifiedVal.name;

                    }
                }
                if (modifiedVal.isSelected) {
                    // if (!modifiedVal.matchColumn) {
                    modifiedVal.colName = modifiedVal.matchColumn
                    modifiedVal.matchColumn = modifiedVal.name;

                    // }
                }
                return {
                    attributeName: modifiedVal.matchColumn,
                    attributeType: modifiedVal.type ? modifiedVal.type : 'text',
                    colName: modifiedVal.colName ? modifiedVal.colName : modifiedVal.name,
                    preference: modifiedVal.preference,
                };
            });

            const csvHeaderRowsData = [];
            // loop on headers and find and match colName from csvHeaderRows if matches then replace that colName with attributeName
            headers.forEach((header) => {
                const matchHeader = csvHeaderRows.find((val) => val.colName === header);
                // if match found then replace colName with attributeName
                // handle duplicate headers
                if (matchHeader) {
                    csvHeaderRowsData.push({
                        attributeName: matchHeader.attributeName,
                        attributeType: matchHeader.attributeType,
                    });
                }
            });
            //handle for variant Level Check parentSku should not be empty
            if(checked){
               const parentSkuIndex=csvHeaderRows.findIndex(val=>val.attributeName==="Parent SKU");
               if(parentSkuIndex!==-1){
                  const colName=csvHeaderRows[parentSkuIndex].colName;
                  const colIndex=headers.findIndex(val=>val===colName);
                  if(colIndex!==-1){
                      const emptyPSKUIndices = [];
                      const emptyRowsIndices = [];

                      const columnValues = await Promise.all(
                          lines.map(async (line, index) => {
                              if (line && JSON.stringify(line) !== JSON.stringify([null])) {

                                  const skuValue = line[colIndex];

                                  // Check if SKU value is empty
                                  if (!skuValue) {
                                      emptyPSKUIndices.push(index + 1);
                                  }

                                  return skuValue;
                              }
                              if (JSON.stringify(line) === JSON.stringify([null])) {
                                  emptyRowsIndices.push(index + 1);
                              }
                          })
                      );
                      if(emptyRowsIndices.length !== 0 || emptyPSKUIndices.length !== 0) {
                          Toast.errorNotification("Parent SKU can't be empty!", 4.5)
                            setOverAllLoading(false);
                          return;
                      }
                  }else {
                      Toast.errorNotification("Cannot find Parent SKU", 4.5)
                      setOverAllLoading(false);
                      return;
                  }
               }else {
                   Toast.errorNotification("Cannot find Parent SKU", 4.5)
                   setOverAllLoading(false);
                   return;
               }
            }
            const dataToCompress = {
                csvHeaderRows: csvHeaderRowsData,
                rows: lines
            };
            const jsonString = JSON.stringify(dataToCompress);
            const compressedData = zlib.deflateSync(jsonString);

            const payload = await dispatch(ProductThunk.importProductCsv({compressedData}));
            if (payload.type === IMPORT_PRODUCT_CSV_FULFILLED) {
                Toast.successNotification("CSV Imported Successfully");
                await clearIndexedDBData();
                setHeaders([]);
                setLines([]);
                setLines([]);
                setUploadProgress(0);
                setUploadedFileName("");
                setFileSizeInMB(0);
                setCall(0);
                setPreferences([]);
                setConstPreferences([]);
                setChangedPreferances(new Set());
                setIsLoaded(false)
                setOverAllLoading(false);
                fileRef.current.value = null;
                dispatch(ProductThunk.productList(
                    {
                        skip: 0,
                        limit: 25
                    }
                ));
                navigate('/product/list')
            }
            setOverAllLoading(false);
        } catch (err) {
            Toast.errorNotification(err)
        }

    };

    const convertCSVtoJSON = (file) => {
        // fileRef.current=null;
        if (!file) {
            return;
        }
        setOverAllLoading(true);
        setIsLoaded(true)
        if (!(file instanceof File)) {
            return Promise.reject("Invalid file parameter");
        }
        Papa.parse(file, {
            dynamicTyping: true,
            complete: (results) => {
                const nullHeaders=results.data[0]?.filter(val=>!val);
                if(nullHeaders?.length){
                    Toast.errorNotification("CSV HEADERS CAN NOT BE EMPTY!");
                    fileRef.current.value=null;
                    setOverAllLoading(false);
                    setIsLoaded(false);
                    return;
                }
                setHeaders(results.data[0]);
                // check for index  null rows in csv
                const emptyRowsIndices = [];
                const tempLines = results.data.slice(1).filter((val, index) => {
                    if (JSON.stringify(val) === JSON.stringify([null])) {
                        emptyRowsIndices.push(index + 1);
                        return false;
                    }
                    return val;
                });
                // console.log(emptyRowsIndices,results.data[0]);
                setDataFromDB(false);

                setUploadedFileName(file.name);
                const fileSizeInMB = file.size / (1024 * 1024);

                setFileSizeInMB(fileSizeInMB);
                //set rows
                setLines(tempLines);

            },
        });
    };
    const columns = [
        {
            title: 'CSV Headers',
            dataIndex: 'name',
            key: 'header',
            render: (text) => <span className={"sku_ui"} >{text}</span>,
        },
        {
            title: 'Selected',
            key: 'selected',
            dataIndex: 'matchColumn',
            align: 'left',
        },
        {
            title: 'Matched',
            key: 'tag',
            dataIndex: 'preference',
            align: 'left',
            render: (text, record) => (
                <span style={{float: 'left'}}>
          {checkData(record.name || "") ? (
              <Tag
                  color={record.preference === 'selected' || record.preference === 'matchWithExisting' ? "green" : "red"}>
                  {record.preference === 'matchWithExisting'
                      ? 'Matched With Existing'
                      : record.preference === 'ignore'
                          ? 'Ignore'
                          : record.preference}
              </Tag>
          ) : (
              <Tag
                  color={record.preference === 'matchWithExisting' || record.preference === 'selected' ? "green" : "red"}>
                  {record.preference === 'createNew'
                      ? 'Create New'
                      : record.preference === 'ignore'
                          ? 'Ignore'
                          : record.preference === 'matchWithExisting' ? 'Matched With Existing' : record.preference}
              </Tag>
          )}

        </span>
            ),
        },
        {
            title: 'Type',
            key: 'type',
            dataIndex: 'type',
            align: 'left',
        },
        // {
        //     title: 'Actions',
        //     key: 'action',
        //     align: 'right',
        //     render: (text, record) => (
        //         <span>
        //   <Button className={"secondary_btn"} onClick={() => showModal(record)}>Change</Button>
        // </span>
        //     ),
        // },

        {
            title: 'Actions',
            key: 'action',
            align: 'right',
            render: (text, record) => (
                record && record.isRequired ? (
                    <div className={"attribute_Select_wrapper"}>
                        <Select
                            className={"attribute_select"}
                            showSearch
                            placeholder="Match Header"
                            style={{width: '100%', marginTop: '10px'}}
                            onChange={(value) => {
                                // Update the preference
                                setSelectedRecord(record)
                                let temp={};
                                const updatedPreferences = preferences.map((item) =>
                                    item.name === record.name
                                        ? (() => {
                                            changedPreferances.add(item.name);
                                            setChangedPreferances(changedPreferances);
                                            if(item.isRequired && item.matchColumn){
                                                const matchAttribute = attributes.find(
                                                    attribute => attribute.nameToShow.toLowerCase() === item.matchColumn.toLowerCase()
                                                );
                                                // const temp=preferences;
                                                temp= {
                                                    name: item?.matchColumn,
                                                    type: matchAttribute ? matchAttribute.type : '',
                                                    preference: matchAttribute ? 'matchWithExisting' : 'createNew',
                                                    matchAttribute: matchAttribute,
                                                };

                                            }
                                            return {
                                                ...item,
                                                preference: 'selected',
                                                matchColumn: value,
                                                // type: 'text',
                                                isSelected: true,

                                                // columName:record.name
                                            };
                                        })()
                                        : item
                                );
                                setPreferences(prevPreferences => {
                                    const combinedPreferences = temp && temp?.name?[...updatedPreferences, ...(temp ? [temp] : [])]:[...updatedPreferences];
                                    return combinedPreferences.reduce((acc, current) => {
                                        if (!acc.some(item => item.name === current.name)) {
                                            acc.push(current);
                                        }
                                        return acc;
                                    }, []);
                                });
                                setModalVisible(false);
                            }}
                            value={record ? preferences.find(val => val.name === record.name).matchColumn : undefined}
                        >
                            {headers.map((header) => (
                                <Option value={header} key={header}>
                                    {header}
                                </Option>
                            ))}
                        </Select>
                    </div>
                ) : (
                    <span>
                <Button className={"secondary_btn"} onClick={() => showModal(record)}>
                    Change
                </Button>
            </span>
                )
            ),
        }

    ];

    const dbPromise = openDB('csvDatabase', 1, {
        upgrade(db) {
            db.createObjectStore('csvData');
        },
    });
    const saveDataToIndexedDB = async (data) => {
        const db = await dbPromise;
        const tx = db.transaction('csvData', 'readwrite');
        const store = tx.objectStore('csvData');
        await store.put(data, 'csvKey');
    };

    const fetchDataFromIndexedDB = async () => {
        setOverAllLoading(true);
        setDataFromDB(true);
        const db = await dbPromise;
        const tx = db.transaction('csvData', 'readonly');
        const store = tx.objectStore('csvData');
        const data = await store.get('csvKey');
        setOverAllLoading(false);
        return data;
    };

    const clearIndexedDBData = async () => {
        const db = await dbPromise;
        const tx = db.transaction('csvData', 'readwrite');
        const store = tx.objectStore('csvData');

        // Clear the content of the object store
        await store.clear();
        setHeaders([]);
        setLines([]);
        setLines([]);
        setUploadProgress(0);
        setUploadedFileName("");
        setFileSizeInMB(0);
        setCall(0);
        setPreferences([]);
        setConstPreferences([]);
        setChangedPreferances(new Set());
        setIsLoaded(false)
        setOverAllLoading(false);
        fileRef.current.value = null;


    };

    useEffect(() => {
        const fetchDataFromDb = async () => {
            try {
                const data = await fetchDataFromIndexedDB();
                if (data) {
                    setPreferences(data.preferences)
                    setHeaders(data.headers);
                    setLines(data.lines);
                    setIsRowsLoaded(false);
                    setUploadedFileName(data.uploadedFileName);
                }
            } catch (error) {
                console.error("Error fetching attributes :", error);
            }
        };
        if (!headers.length && dataFromDB) {
            fetchDataFromDb();
        }
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        const fetchData = async () => {
            try {
                const payload = await dispatch(AttributeThunk.fetchAttributes(signal));
                await dispatch(AttributeThunk.attrbuteGroupList());
                if (payload.type === FETCH_ATTRIBUTE_REJECTED) {
                    Toast.errorNotification(payload.error.message);
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        fetchData();

        // Cleanup function
        return () => {
            abortController.abort();
        };
    }, [dispatch]);


    const downloadCSV = () => {
        // Default keys
        const defaultKeys = ['sku', 'category', 'brand', 'name', 'description', 'price', 'sale_price', 'sale_price_start_date', 'sale_price_end_date', 'quantity'];

        // Extract the 'name' properties from the array of objects
        const names = data.map(item => item.name);

        // Filter out default keys if they exist in the names
        const filteredNames = names.filter(name => !defaultKeys.includes(name));

        // Concatenate default keys and the remaining names
        const csvContent = [...defaultKeys, ...filteredNames].join(",") + "\n";

        // Create a Blob
        const blob = new Blob([csvContent], { type: 'text/csv' });

        // Create a download link and trigger the click event
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'pim_sample.csv';
        link.click();
    };


    const openUploader = () => {
        if (fileRef.current) {
            fileRef.current.click();
        }
    };
    const handleAutoOpen = (selectedRecord) => {
        return changedPreferances.has(selectedRecord.name);

    }
    const handleCreateNew = (selectedType) => {
        changedPreferances.delete(selectedRecord.name);
        setChangedPreferances(changedPreferances);
        const updatedPreferences = preferences.map((item) =>
            item.name === selectedRecord.name
                ? {...item, preference: 'createNew', matchColumn: undefined, type: selectedType}
                : item
        );
        setPreferences(updatedPreferences);


    }
    const handleModalAction = (action, selectedType) => {
        setToogle(false);
        if (action === 'matchWithExisting') {
            setCreateNewToggle(false)
            setToogle(true);
        } else if (action === 'createNew') {
            setToogle(false);
            changedPreferances.delete(selectedRecord.name);
            setChangedPreferances(changedPreferances);
            setCreateNewToggle(true);
            // handleCreateNew(selectedType); // Implement your logic for creating a new attribute with the selected type
        } else if (action === 'required') {
            setRequiredToggle(true)
            setCreateNewToggle(false)
            setToogle(false);
        } else {
            changedPreferances.delete(selectedRecord.name);
            setChangedPreferances(changedPreferances);
            const updatedPreferences = preferences.map((item) =>
                item.name === selectedRecord.name
                    ? {...item, preference: action, matchColumn: undefined, type: selectedType}
                    : item
            );

            setPreferences(updatedPreferences);
            setModalVisible(false);
            setSelectedRecord(null);
        }
        // Close the modal
    };


    const handleMatchExisting = (selectedApiData) => {
        setSelectedRecord(selectedApiData);
        // Assuming there's a property named 'propertyName' in each object
        let tempData = data.filter((val) => val.name === selectedApiData);
        setFilteredData(tempData);
        const updatedPreferences = preferences.map((item) =>
            item.name === selectedRecord.name
                ? (() => {
                    changedPreferances.add(item.name);
                    setChangedPreferances(changedPreferances);
                    return {
                        ...item,
                        preference: 'matchWithExisting',
                        matchColumn: selectedApiData,
                        type: tempData[0].type,
                    };
                })()
                : item
        );
        setPreferences(updatedPreferences);
        // Close the modal
        setModalVisible(false);
        setSelectedRecord(null);
        setToogle(false);
        setFilteredData(data);
    };

    const handleMatchRequired = (selectedApiData) => {
        // match with required type and name
        setSelectedRecord(selectedApiData);

        //do it like create new but with required type and name
        const updatedPreferences = preferences.map((item) =>
            item.name === selectedRecord.name
                ? (() => {
                    changedPreferances.add(item.name);
                    setChangedPreferances(changedPreferances);
                    return {
                        ...item,
                        preference: 'selected',
                        matchColumn: selectedApiData,
                        isSelected: true,
                        type: item.type,
                    };
                })()
                : item
        );
        setPreferences(updatedPreferences);

        // Close the modal
        setModalVisible(false);
        setSelectedRecord(null);
        setToogle(false);
        setFilteredData(data);
        setRequiredToggle(false)

    }
    useEffect(() => {
        setFilteredData(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [data])


    return (
        <div className={"right_inner_wrapper"}>

            <Spin spinning={overAllLoading}>
                <div className={"right_inner_header"}>
                    <Title level={4}>Select CSV File</Title>

                    <input
                        type="file"
                        ref={fileRef}
                        id="file-upload"
                        onChange={(event) => convertCSVtoJSON(event.target.files[0])}
                        style={{display: 'none'}}
                        accept=".csv"
                    />


                    <Button
                        type="secondary"
                        className="secondary_icon_btn"
                        onClick={downloadCSV}
                        icon={<DownloadOutlined/>}>
                        Download Sample CSV
                    </Button>

                </div>

                <div className={"file_upload_wrapper"}>
                    <div className={"file_upload_btn"}>
                        <div className={"upload_inner_sec"}>
                            <Button
                                id={'file-upload'}
                                icon={<UploadOutlined/>}
                                onClick={openUploader}
                                disabled={isLoaded}
                            >
                                Choose File
                            </Button>
                            {uploadedFileName && (
                                <p style={{marginLeft: '8px'}}>
                                    <PaperClipOutlined style={{marginRight: '4px'}}/>
                                    {uploadedFileName}
                                </p>
                            )}
                        </div>
                    </div>


                    {headers.length > 0 && (
                        <div className={"uplaod_action_btn"}>
                            <Row align="middle" style={{ marginRight: '8px',padding:"8px",gap:"8px" }}>
                                <Col>
                                    <Switch checked={checked} onChange={()=> setChecked(!checked)}></Switch>
                                </Col>
                                <Col>
                                    Variant Upload
                                </Col>
                            </Row>
                            <Button
                                type="secondary"
                                className="secondary_icon_btn"
                                disabled={isRowsLoaded}
                                onClick={async () => await clearIndexedDBData()}
                            >
                                Clear
                            </Button>
                            <Button
                                type="primary"
                                className="primary_btn"
                                disabled={isRowsLoaded}
                                onClick={handleSendData}
                            >
                                Submit
                            </Button>
                        </div>

                    )}
                </div>


                <div>

                    {uploadProgress > 0 ? (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginBottom: '20px'
                        }}>

                            <Spin
                                indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}
                                spinning={isLoaded}
                            />
                            <br></br>
                            <Progress type="circle" percent={uploadProgress} status="active"/>


                            <p style={{marginTop: '8px'}}>{`Uploading: ${uploadedFileName} (${(fileSizeInMB).toFixed(2)} MB)`}</p>
                        </div>
                    ) : (
                        <div className={"custom_table_ui"}>
                            <Table
                                dataSource={preferences && preferences.length>0 ? [...preferences.filter(val => val.name === 'SKU'),...preferences.filter(val=>val.name==='Product Name'),...preferences.filter(val=>val.name==='Price'),
                                    ...preferences.filter(val => val.name !== 'SKU' && val.name!=='Price' && val.name!=='Product Name')] : []}
                                columns={columns} pagination={false} rowKey={'name'}/>
                        </div>

                    )}

                    <Modal
                        title="Select Action"
                        className={"custom_modal csv_selection"}
                        open={modalVisible}
                        onOk={() => {
                            handleModalAction('createNew');
                            setModalVisible(false);
                            setSelectedRecord(null);
                            setCreateNewToggle(false)
                        }}
                        onCancel={() => {
                            setModalVisible(false);
                            setSelectedRecord(null);
                            setCreateNewToggle(false)
                        }}
                    >
                        {

                            selectedRecord && selectedRecord.isRequired ? <>
                                {selectedRecord.isRequired && (
                                    <Button onClick={() => handleModalAction('required')}>Choose </Button>
                                )}
                            </> : <>
                                {selectedRecord && constPreferences.find(val => val.name === selectedRecord.name) && (
                                    <Button onClick={() => handleModalAction('createNew')}>Create New</Button>
                                )}
                                <Button onClick={() => handleModalAction('matchWithExisting')

                                }>Match With Existing</Button>
                                <Button onClick={() => handleModalAction('ignore')}>Ignore</Button>


                                {selectedRecord && (handleAutoOpen(selectedRecord) || (toogle && selectedRecord.preference)) && (

                                    <div className={"select_wrapper attribute_Select_wrapper"}>
                                        <Select
                                            className={"attribute_select"}
                                            popupClassName={"filter_dropdown"}
                                            showSearch
                                            placeholder="Seach And Select Attributes"
                                            value={selectedRecord ? preferences.find(val => val.name === selectedRecord.name).matchColumn : undefined}
                                            onChange={(value) => handleMatchExisting(value)}
                                            style={{width: '100%', marginTop: '10px'}}
                                        >
                                            {filteredData.map((apiItem) => (
                                                <Option key={apiItem.name} value={apiItem.name}>
                                                    <div className={"filter_dropdown_value"}>
                                                        <div className={"name_sec"}> {apiItem.name}</div>
                                                        <div className={"select_type"}>({apiItem.type})</div>
                                                    </div>
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                            </>
                        }


                        {selectedRecord && createNewToggle && !requiredToggle && (
                            // give types to select
                            <Select
                                showSearch
                                placeholder="Select Type"
                                style={{width: '100%', marginTop: '10px'}}
                                value={selectedRecord ? preferences.find(val => val.name === selectedRecord.name).type : undefined}
                                onChange={(value) => handleCreateNew(value)}
                            >
                                <Option value="text">Text</Option>
                                <Option value="number">Number</Option>
                                <Option value="dropdown">Dropdown</Option>
                                <Option value="multiselect">Multiselect</Option>
                                <Option value="date">Date</Option>
                                <Option value="boolean">Boolean</Option>
                                <Option value="media">Media</Option>
                                <Option value="url">URL</Option>
                                <Option value="html">HTML</Option>
                                {/* Add other types as needed */}
                            </Select>

                        )}
                        {selectedRecord && requiredToggle && (
                            // select from csv headers
                            <Select
                                showSearch
                                placeholder="Select Type"
                                style={{width: '100%', marginTop: '10px'}}
                                value={selectedRecord ? preferences.find(val => val.name === selectedRecord.name).type : undefined}
                                onChange={(value) => handleMatchRequired(value)}
                            >
                                {headers.map((header) => (
                                    <Option value={header}>{header}</Option>
                                ))}
                                {/* Add other types as needed */}
                            </Select>

                        )


                        }


                    </Modal>
                </div>
            </Spin>
        </div>
    );
};

export default TestCSV;
