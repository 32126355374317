import {createAsyncThunk} from '@reduxjs/toolkit';
import productService from "../../redux/actions/ProductAction";
import {
    FETCH_PRODUCT, ADD_PRODUCT, IMPORT_PRODUCT_CSV, PRODUCT_LIST, PRODUCT_DETAIL,
    PRODUCT_SKU_VALIDATION, VARIANT_UNLINK, PRODUCT_DELETE, PRODUCT_EDIT, EDIT_COLUMN_PRODUCT_LIST,
    PRODUCT_BULK_DELETE, PRODUCT_ASSET_UPLOAD, PRODUCT_ASSET_DELETE, PRODUCT_FILTER_SAVE, EXPORT_PRODUCTS
} from '../types/ProductActionTypes'

const ProductThunk = {

    fetchProducts : createAsyncThunk(FETCH_PRODUCT, async () => {
        try{
            const response = await productService.getProducts();
            return response?.data?.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }

    }),

    addProduct : createAsyncThunk(ADD_PRODUCT, async (newProduct) => {
        try{
            const response = await productService.addProduct(newProduct);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    importProductCsv: createAsyncThunk(IMPORT_PRODUCT_CSV, async ({compressedData}) => {
        try {
            const response = await productService.importProductCsv(compressedData);
            return response.data;

        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    productList: createAsyncThunk(PRODUCT_LIST, async ({skip,limit,filters,search,defaultView}) => {
        try {
            const response =  await productService.productList(skip,limit,filters,search,defaultView);
            return response.data;

        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    productDetail: createAsyncThunk(PRODUCT_DETAIL, async ({id = "", sku = ""}) => {
        try {
            let response;
            if(id !== "") {
                response = await productService.productDetail({id, sku: ""});
            }else{
                response = await productService.productDetail({id : "", sku: sku});
            }
            return response.data;

        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    productSkuValidation: createAsyncThunk(PRODUCT_SKU_VALIDATION, async ({sku="", excludeProductId=""}) => {
        try {
            const response = await productService.skuValidation(sku, excludeProductId);
            return response.data;

        } catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    variantUnlink : createAsyncThunk(VARIANT_UNLINK, async (variantIdList) => {
        try{
            const response = await productService.variantUnlink(variantIdList);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    productDelete : createAsyncThunk(PRODUCT_DELETE, async (id) => {
        try{
            const response = await productService.productDelete(id);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    productEdit : createAsyncThunk(PRODUCT_EDIT, async (product) => {
        try{
            const response = await productService.productEdit(product);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    editColumnsProductList : createAsyncThunk(EDIT_COLUMN_PRODUCT_LIST, async (attributeList) => {
        try{
            const response = await productService.editColumnsProductList(attributeList);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    productBulkDelete : createAsyncThunk(PRODUCT_BULK_DELETE, async (productIdList) => {
        try{
            const response = await productService.productBulkDelete(productIdList);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    productAssetUpload : createAsyncThunk(PRODUCT_ASSET_UPLOAD, async (formData) => {
        try{
            const response = await productService.productAssetUpload(formData);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    prodctAssetDelete : createAsyncThunk(PRODUCT_ASSET_DELETE, async (body) => {
        try{
            const response = await productService.productAssetDelete(body);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    productFilterSave : createAsyncThunk(PRODUCT_FILTER_SAVE, async (filter) => {
        try{
            const response = await productService.productFilterSave(filter);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    }),

    exportProducts : createAsyncThunk(EXPORT_PRODUCTS, async (filter) => {
        try{
            const response = await productService.exportProducts(filter);
            return response.data;
        }catch (e) {
            throw e?.response?.data || e.message;
        }
    })

}

export default ProductThunk;