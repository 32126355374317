import ProductThunk from "../../../../redux/thunk/ProductThunk";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {Avatar, Image, Tag, Tooltip} from "antd";
import {Link} from "react-router-dom";
import AttributeThunk from "../../../../redux/thunk/AttributeThunk";
import {CaretDownOutlined, CaretRightOutlined} from '@ant-design/icons';
import dayjs from "dayjs";
import Toast from "../../../../components/Notification/Toast";
import {filterOptions} from "../../../../utils/filtersOptions";
import {toProperCase} from "../../../../utils/helpers";
import {PRODUCT_BULK_DELETE_FULFILLED, PRODUCT_LIST_FULFILLED} from "../../../../redux/types/ProductActionTypes";
import ViewThunk from "../../../../redux/thunk/ViewThunk";
import ProductListWrapper from "./ProductListWrapper";
import {setDefaultView, setFilterViewName} from "../../../../redux/slices/ProductSlice";


const initialState = {
    searchedProductList: [],
    isEditable: false,
    checked: false,
    selectedOption: null,
    secondScreen: false,
    identifier: '',
    searchedAttributes: [],
    selectedAttribute: null,
    selectedAttributeFilter: null,
    selectedAttributeFilters: [],
    isInitial: true,
    activeButton: false,
    isDisplay: true,
    filterVal: null,
    isOrAnd: '',
    checkedValue: '',
    editFilter: null,
    index: {},
    searchVal: null,
    updateIndex: -1,
    isPaginationProductLoading: false,
    val: null,
    filterModalType: null,
    openFilterModal: false,
    modify: false,
    filterName: '',
    updatedFilter: true,
    selectedFilterName: null,
    defaultViewLocal: null
};

const ProductList = () => {
    let {
        products,
        filterViewName,
        isLoading,
        parentCount,
        variantsCount,
        count,
        singleProduct,
        skip,
        limit,
        filters,
        isCsvImported,
        defaultView
    } = useSelector((state) => state.products);
    const dispatchIdRef = useRef(0);
    const dispatch = useDispatch();
    const [states, setStates] = useState(initialState);
    // const {user} = useSelector(state => state.user);
    const {attributes} = useSelector(state => state.attributes);
    const {views} = useSelector(state => state.view);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: skip === 1 ? 1 : (skip / limit) + 1,
            pageSize: limit,

        },
    });

    // useEffect(() => {
    //     if(views && !defaultViewLocal && defaultView){
    //         const value=views.find(val=>val.name===user.staff.defaultViewName);
    //         if (value ){
    //             setDefaultViewLocal(value);
    //             dispatch(setFilterViewName(value.name));
    //             setUpdatedFilter(true);
    //             setSelectedAttributeFilters(value.filter);
    //         }
    //     }
    // }, [views,defaultViewLocal,defaultView]);


    useEffect(() => {
        setStates(prevState => ({...prevState, updatedFilter: true, selectedAttributeFilters: filters}));
        dispatch(ViewThunk.listView());

        dispatch(ProductThunk.productList({
            skip: skip === 1 ? 0 : skip,
            limit: limit,
            filters: !filters ? [] : filters,
            defaultView: defaultView
        }));
        dispatch(AttributeThunk.fetchAttributes());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const custom = attributes.filter(val => val.name === 'sku' || val.name === 'price' || val.name === 'product_name' || val.name === 'taxonomy_categories' || val.name === 'brand_name' || val.name === 'parent_sku');
        const notCustom = attributes.filter(val => !(val.name === 'sku' || val.name === 'price' || val.name === 'product_name' || val.name === 'taxonomy_categories' || val.name === 'brand_name' || val.name === 'parent_sku'))
        setStates(prevState => ({...prevState, searchedAttributes: [...custom, ...notCustom]}));
    }, [attributes])

    useEffect(() => {
        setStates(prevState => ({...prevState, searchedProductList: products}));


    }, [products])

    useEffect(() => {
        let timeout;

        if (states.searchVal && states.searchVal.length >= 3) {
            const currentDispatchId = Date.now(); // Use timestamp as a unique identifier
            dispatchIdRef.current = currentDispatchId;


            timeout = setTimeout(() => {
                // Check if the dispatchId matches the currentDispatchId before dispatching
                if (dispatchIdRef.current === currentDispatchId) {


                    dispatch(ProductThunk.productList({
                        limit: limit,
                        skip: 0,
                        search: states.searchVal,
                        filters: states.selectedAttributeFilters,
                        defaultView: defaultView
                    }));
                }
            }, 400);
        } else if (states.searchVal === '') {
            clearTimeout(timeout)
            setTimeout(() => {
                // Check if the dispatchId matches the currentDispatchId before dispatching

                dispatch(ProductThunk.productList({
                    limit: limit,
                    skip: 0,
                    search: states.searchVal,
                    filters: states.selectedAttributeFilters,
                    defaultView: defaultView
                }));

            }, 400);
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [states.searchVal]);

    // useEffect(() => {
    //     if(updatedFilter){
    //         setUpdatedFilter(false);
    //     } else if(filterViewName && filters && filters.length && !defaultView ){
    //         let match=arraysOfArraysOfObjectsAreEqual(filters,selectedAttributeFilters);
    //         if(!match){
    //             dispatch(setFilterViewName(null));
    //         }
    //     }
    //     setSecondScreen(false);
    // }, [selectedAttributeFilters])

    useEffect(() => {
        const getFilter = async () => {
            setStates(prevState => ({...prevState, isPaginationProductLoading: true}));
            // console.log(currentFilters);
            await dispatch(ProductThunk.productList({
                skip: 0,
                limit: limit,
                filters: states.selectedAttributeFilters,
                defaultView: defaultView
            }));
            setStates(prevState => ({...prevState, isPaginationProductLoading: false}));
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    current: (skip - 1) * limit,
                    pageSize: limit,
                },
            })
        }
        if (states.isInitial) {
            setStates(prevState => ({...prevState, isInitial: false}));

        } else {
            if (states.selectedAttributeFilters && filters !== states.selectedAttributeFilters) getFilter();
        }
    }, [filters, states.selectedAttributeFilters])


    const toogleDefaultView = () => {
        if (defaultView) {

            dispatch(setDefaultView(false));
            setStates(prevState => ({...prevState, selectedAttributeFilters: []}));

        } else {
            dispatch(setDefaultView(true));
            dispatch(setFilterViewName(states.defaultViewLocal?.name));
            setStates(prevState => ({...prevState, selectedAttributeFilters: states.defaultViewLocal?.filter}));
        }
    }
    const handleSetFilter = (val) => {
        setStates(prevState => ({...prevState, val: val, selectedAttributeFilter: val}));

        if ((val && val !== '') || val === false || val === 0) {
            setStates(prevState => ({...prevState, activeButton: true}));
        } else {
            setStates(prevState => ({...prevState, activeButton: false}));
        }

    }
    const clearFilterData = () => {
        setStates(prevState => ({
            ...prevState, activeButton: false, selectedAttributeFilter: null,
            selectedAttribute: null, checked: false, checkedValue: '', filterVal: null,
            selectedOption: null, val: null, editFilter: null
        }));

        const custom = attributes.filter(val => val.name === 'sku' || val.name === 'price' || val.name === 'product_name' || val.name === 'taxonomy_categories' || val.name === 'brand_name' || val.name === 'parent_sku');
        const notCustom = attributes.filter(val => !(val.name === 'sku' || val.name === 'price' || val.name === 'product_name' || val.name === 'taxonomy_categories' || val.name === 'brand_name' || val.name === 'parent_sku'))
        setStates(prevState=>({...prevState,searchedAttributes: [...custom,...notCustom],index: null,updateIndex: -1,secondScreen: false,isEditable: false}));


    }
    const handleOpenFilterModal = () => {
        dispatch(ViewThunk.listView());
        setStates(prevState=>({...prevState,filterName: null,modify: false,openFilterModal: true}));
    }
    const handleFilterModalClose = () => setStates(prevState=>({...prevState,openFilterModal: false}));
    const clearSelectedFilter = () => {
        dispatch(setDefaultView(false))
        dispatch(setFilterViewName(null));
        setStates(prevState=>({...prevState,selectedAttributeFilters: []}));

    }
    const handleSaveFilter = async () => {
        dispatch(setDefaultView(false))
        setStates(prevState=>({...prevState,updatedFilter: true}));
        let payload;
        if (states.modify) {
            const body = {
                "id": views[states.selectedFilterName]?.id.toString(),
                "name": views[states.selectedFilterName]?.name.toString(),
                "filter": states.selectedAttributeFilters
            }
            payload = await dispatch(ViewThunk.editView(body));
        } else {
            const body = {
                "name": states.filterName.toString(),
                "filter": states.selectedAttributeFilters
            }
            payload = await dispatch(ViewThunk.addView(body));
        }
        if (payload?.type === 'view/editView/fulfilled' || payload?.type === 'view/addView/fulfilled') {
            dispatch(setFilterViewName(states.filterName ? states.filterName.toString() : views[states.selectedFilterName]?.name.toString()));
        } else if (payload?.type === 'view/editView/rejected' || payload?.type === 'view/addView/rejected') {
            Toast.errorNotification(payload?.error.message);
        }
    }

    const handleLoadFilter = () => {
        dispatch(setDefaultView(false))
        setStates(prevState=>({...prevState,updatedFilter: true}));
        dispatch(setFilterViewName(views[states.filterName]?.name));
        setStates(prevState=>({...prevState,selectedAttributeFilters: views[states.filterName]?.filter}));

    }

    //here
    const handleSearch = (event) => {
        const value = event.target.value;

        if (!value) {
            setStates(prevState => ({...prevState, searchVal: ''}));
        } else {
            setStates(prevState => ({...prevState, searchVal: value}));
        }

    };


    const getWidth = (each) => {
        if (each === 'label') {
            return 200
        } else if (each === 'status') {
            return 200
        } else if (each === 'created_at') {
            return 200
        } else if (each === 'updated_at') {
            return 200
        } else if (each === 'variation_of') {
            return 200
        } else if (each === 'view') {
            return 200
        } else if (each === 'retail_price') {
            return 150
        } else if (each === 'description') {
            return 250
        } else if (each === 'price') {
            return 70
        } else if (each === 'name') {
            return 200
        } else if (each === 'brand') {
            return 200
        } else if (each === 'thumbnail') {
            return 100
        } else if (each === 'created_date') {
            return 200
        } else if (each === 'last_modified') {
            return 200
        } else if (each === 'status') {
            return 200
        } else if (each === 'brand_name') {
            return 90
        } else if (each === 'parent_sku') {
            return 100
        } else if (each === 'taxonomy_categories') {
            return 120
        } else if (each === 'labels') {
            return 200
        } else if (each === 'variation_of') {
            return 200
        } else if (each === 'assets') {
            return 200
        } else if (each === 'categories') {
            return 200
        } else if (each === 'is_fk_advantage_product') {
            return 300
        } else if (each === 'media_app') {
            return 200
        } else if (each === 'asset_type') {
            return 200
        } else if (each === 'asset_name') {
            return 200
        }
        return 200
    }

    const excludedKeys = ["id", "children"];

    // dynamicallly add columns
    let columns = (products.length && Object.keys(products[0])

        .filter(each => !excludedKeys.includes(each))
        .flatMap(each => {
                if (each === 'sku') {
                    return [
                        // Add more before sku columns as needed
                        {
                            key: 'view',
                            title: ' ',
                            dataIndex: 'view',
                            className: 'toogle_btn',
                            fixed: true,
                            width: 5,
                        },
                        {
                            title: toProperCase(each),
                            dataIndex: each,
                            key: each,
                            width: 100,
                            fixed: true,
                            render: (text, record) => (
                                <Link className="sku_text"
                                      to={`/product/details?id=${record.id || record._id}/attributes`}>{text}</Link>
                            )
                        },
                        {
                            key: 'separator',
                            title: ' ',
                            dataIndex: 'separator',
                            className: 'separator',
                            fixed: true,
                            width: 5,
                        },
                        // Add more columns after  as needed
                    ];
                } else {

                    return {
                        title: toProperCase(each),
                        dataIndex: each,
                        key: each,
                        // width
                        width: getWidth(each),
                        render: (text, record) => {
                            if (each === 'status') {
                                return <Tag
                                    className={text ? text === 'Draft' ? 'draft_tag' : text === 'Completed' ? 'complete_tag' : 'archived_tag' : "draft_tag"}>
                                    {text ? text : "N/A"}
                                </Tag>
                            }
                            if (each === 'created_at') {
                                return <p>{text ? dayjs(text).format('MMM D, YYYY') : "N/A"}</p>
                            }
                            if (each === 'updated_at') {
                                return <div className="last_modified">
                                    <p className={"text_ellipes"}>{text ? dayjs(text).format('MMM D, YYYY') : "N/A"}</p>
                                </div>
                            }

                            if (each === 'thumbnail') {
                                return (
                                    <Avatar
                                        key={states.index}
                                        shape="square"
                                        size={40}

                                        icon={
                                            <Image
                                                preview={false}
                                                src={text ? text : "https://placehold.co/400"} alt={'img'}/>
                                        }
                                    />
                                )
                            }
                            if (each === 'price') {
                                return <p>{text ? `$${text}` : "N/A"}</p>
                            }
                            if (Array.isArray(text) && text?.length && typeof text[0] === 'object') {
                                return <>

                                    {text.length && text[0].image?<>
                                        {(text.slice(0,1)||[]).map((tag, index) => (
                                            <Tag key={index}>{tag.nameToShow}</Tag>
                                        ))}

                                        {(text.slice(1)|| []).length > 0 && (
                                            <Tooltip
                                                title={
                                                    <div>
                                                        {(text.slice(1)|| []).map((tag, index) => (
                                                            <Tag key={index}>{tag.nameToShow}</Tag>
                                                        ))}
                                                    </div>
                                                }
                                            >
                                                <Tag className="tag-more">+{(text.slice(1)|| []).length} more</Tag>
                                            </Tooltip>
                                        )}
                                    </>
                                        :text.slice(0, 1).map((val, index) => (
                                            index === 0 && (
                                                <Avatar
                                                    key={index}
                                                    shape="square"
                                                    size={40}
                                                    icon={
                                                        val?.type === 'image' ? (
                                                            <Image
                                                                preview={false}
                                                                src={val?.link || val?.image}/>
                                                        ) : val?.type === 'video' ? (
                                                            <video width="40" height="40" controls>
                                                                <source src={val?.link} type="video/mp4"/>
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : (
                                                            <></>
                                                        )
                                                    }
                                                />
                                            )
                                        )
                                    )
                                    }


                                </>
                            }
                            if (Array.isArray(text)) {
                                // use tags for array
                                return (
                                    <div className="tags custom_table_tags">
                                        {text.slice(0, 2).map((val, index) => (
                                            index % 2 === 0 ? (
                                                <Tag key={index} color="blue">{val}</Tag>
                                            ) : (
                                                <Tag key={index} color="blue">{val}</Tag>
                                            )
                                        ))}
                                    </div>
                                );
                            }


                            return text !== null && text !== undefined ? text === true ? "True" : text === false ? "False" : text : "N/A";

                        }
                    };
                }
            }
        )) || [
        {
            key: 'view',
            title: ' ',
            dataIndex: 'view',
            className: 'toogle_btn',
            fixed: true,
            width: 10,
        },
        {
            key: 'separator',
            title: ' ',
            dataIndex: 'separator',
            className: 'separator',
            fixed: true,
            width: 10,
        },
        {
            key: 'sku',
            title: 'SKU',
            dataIndex: 'sku',
            fixed: true,
            width: 70,
            render: (text, record) => (
                // <></>
                <Link className="sku_text" to={`/product/details?id=${record.id || record._id}`}>{text}</Link>
            )
        },
    ];


    const handleSearchAttribute = (value) => {
        let temp = attributes.filter(val => val.nameToShow.toLowerCase().includes(value.toLowerCase()));
        const custom = temp.filter(val => val.name === 'sku' || val.name === 'price' || val.name === 'product_name' || val.name === 'taxonomy_categories' || val.name === 'brand_name' || val.name === 'parent_sku');
        const notCustom = temp.filter(val => !(val.name === 'sku' || val.name === 'price' || val.name === 'product_name' || val.name === 'taxonomy_categories' || val.name === 'brand_name' || val.name === 'parent_sku'))
        setStates(prevState => ({...prevState, searchedAttributes: [...custom, ...notCustom]}));
    }
    const onSearch = (value) => {
        if (states.editFilter) {
            if (value !== states.editFilter.name) {
                setStates(prevState => ({...prevState, editFilter: null}));
            }
        }
        setStates(prevState => ({...prevState, selectedOption: null}));
        let temp = attributes.find(val => val.nameToShow === value);
        let t = filterOptions.filter(val => val.name.toLowerCase() === temp.type.toLowerCase());
        t ? setStates(prevState => ({...prevState, filterVal: t[0]})) : setStates(prevState => ({...prevState, filterVal: null}));
        setStates(prevState => ({...prevState, selectedAttribute: temp}));
    }
    const onSearchEdit = (value) => {
        let temp = attributes.find(val => val.name === value.name);
        let t = filterOptions.filter(val => val.name.toLowerCase() === temp.type.toLowerCase());
        setStates(prevState => ({...prevState, filterVal: t[0], selectedAttribute: temp}));
    }

    const CustomExpandIcon = ({record, onExpand, expanded}) => (
        <span className={"mutliple_sku"} style={{
            display: record.children && record.children.length ? "inline-flex" : 'none  ',
        }} onClick={(e) => onExpand(record, e)}>
            {record.children && record.children.length ? record.children.length : null}
            {record.children && record.children.length ? expanded ? <CaretDownOutlined/> : <CaretRightOutlined/> : null}
        </span>
    );
    const arrangeFilter = (filter) => {
        let currentFilters = filter;

        if (filter && filter.length > 0) {
            setDivVisible(true);

            const temp = currentFilters.map((value) => {
                // console.log(value);
                if(!Array.isArray(value)) {
                    let type = value.type;
                    const typeAttribute=attributes.find(val => val.name.toLowerCase()===value.name);
                    if(typeAttribute){
                        type=typeAttribute.type;
                    }
                    let newValue= !Array.isArray(value?.value)?value?.value:value?.value?.map((v) => {
                        if (typeof v === 'string' && v.includes('image')) {
                            type = 'objectarray';
                            const parsedValue = JSON.parse(v);
                            return parsedValue?.name;
                        }
                        return v;
                    });
                    return {...value,value:newValue||null, type};
                }
                return (value||[])?.map((val) => {
                    let type = val.type;
                    const typeAttribute=attributes.find(v => v.name.toLowerCase()===val.name);
                    if(typeAttribute){
                       type=typeAttribute.type;
                    }

                    const newValues = !Array.isArray(value?.value)?val?.value:val?.value?.map((v) => {
                        if (typeof v === 'string' && v.includes('image')) {
                            // Convert string to object if it contains 'image'
                            type = 'objectarray';
                            const parsedValue = JSON.parse(v);
                            return parsedValue?.name;
                        }
                        return v;
                    });

                    return { ...val, type, value: newValues || null };
                });
            });

            // console.log("Transformed Filters:", temp);
            return temp; // Return the transformed filters instead of the original filters
        }

        // console.log("Original Filters:", currentFilters);
        return currentFilters; // Return the original filters if no transformation is needed
    };

    const handleApplyFilter = async () => {
        dispatch(setDefaultView(false));
        dispatch(setFilterViewName(null))

        if (states.editFilter || states.isEditable) {
            let temp = [...states.selectedAttributeFilters];
            let tempMatch = states.selectedOption.name ? states.selectedOption.name : states.selectedOption;
            let isChatacterType = ((tempMatch !== (states.checked ? states.checkedValue ? states.checkedValue.name : states.editFilter?.type : states.selectedOption ? states.selectedOption.name ? states.selectedOption.name : states.selectedOption : states.editFilter?.type)) && states.selectedAttribute?.type !== 'date');
            let tempVal = [...temp[states.index.ind]];

            let value = (states.selectedAttributeFilter || states.selectedAttributeFilter === false || states.selectedAttributeFilter === 0) ? states.selectedAttributeFilter : null;
            tempVal[states.index.index] = {
                "name": states.selectedAttribute ? states.selectedAttribute.name : states.editFilter?.name,
                'match': tempMatch,
                "type": states.checked ? states.checkedValue ? states.checkedValue.name : states.editFilter?.type : states.selectedOption ? states.selectedOption.name ? states.selectedOption.name : states.selectedOption : states.editFilter?.type,
                "value": (tempMatch === 'is not defined' || tempMatch === 'is defined') ? null : isChatacterType ? value ? parseFloat(value) : null : (value || value === false || value === 0) ? (states.selectedAttribute?.type === 'number') ? parseFloat(value) : states.selectedAttribute?.type === 'decimal' ? parseFloat(value) : value : null
            }

            temp[states.index.ind] = [...tempVal];
            setStates(prevState => ({...prevState, selectedAttributeFilters: ([...arrangeFilter(temp)])}));


        } else if (states.updateIndex !== -1) {
            let isCharacterType = ((states.checked ? states.checkedValue.name : states.selectedOption.name ? states.selectedOption.name : states.selectedOption) !== (states.selectedOption.name ? states.selectedOption.name : states.selectedOption) && states.selectedAttribute?.type !== 'date');
            let temp = {
                "name": states.selectedAttribute.name,
                'match': states.selectedOption.name ? states.selectedOption.name : states.selectedOption,
                "type": states.checked ? states.checkedValue.name : states.selectedOption.name,
                "value": isCharacterType ? states.selectedAttributeFilter ? parseFloat(states.selectedAttributeFilter) : null : (states.selectedAttributeFilter || states.selectedAttributeFilter === false || states.selectedAttributeFilter === 0) ? (states.selectedAttribute?.type === 'number') ? parseFloat(states.selectedAttributeFilter) : states.selectedAttribute?.type === 'decimal' ? parseFloat(states.selectedAttributeFilter) : states.selectedAttributeFilter : states.selectedAttributeFilter
            }
            let t = [...states.selectedAttributeFilters];
            t[states.updateIndex] = [...t[states.updateIndex], temp]
            setStates(prevState => ({...prevState, selectedAttributeFilters: [...arrangeFilter(t)]}));
        } else {
            if (!states.selectedAttributeFilters || states.selectedAttributeFilters.length === 0) {
                let isCharacterType = ((states.checked ? states.checkedValue.name : states.selectedOption.name ? states.selectedOption.name : states.selectedOption) !== (states.selectedOption.name ? states.selectedOption.name : states.selectedOption) && states.selectedAttribute?.type !== 'date');
                let temp = [{
                    "name": states.selectedAttribute.name,
                    'match': states.selectedOption.name ? states.selectedOption.name : states.selectedOption,
                    "type": states.checked ? states.checkedValue.name : states.selectedOption.name ? states.selectedOption.name : states.selectedOption,
                    "value": isCharacterType ? states.selectedAttributeFilter ? parseFloat(states.selectedAttributeFilter) : null : (states.selectedAttributeFilter || states.selectedAttributeFilter === false || states.selectedAttributeFilter === 0) ? (states.selectedAttribute?.type === 'number') ? parseFloat(states.selectedAttributeFilter) : states.selectedAttribute?.type === 'decimal' ? parseFloat(states.selectedAttributeFilter) : states.selectedAttributeFilter : states.selectedAttributeFilter
                }]
                setStates(prevState => ({...prevState, selectedAttributeFilters: [[...arrangeFilter(temp)]]}));
            } else {
                if (states.isOrAnd === 'and') {
                    let temp = [...states.selectedAttributeFilters];
                    let isCharacterType = ((states.checked ? states.checkedValue.name : states.selectedOption.name ? states.selectedOption.name : states.selectedOption) !== (states.selectedOption.name ? states.selectedOption.name : states.selectedOption) && states.selectedAttribute?.type !== 'date');
                    let t = [...temp[temp.length === 0 ? 0 : temp.length - 1]];
                    t = [...t, {
                        "name": states.selectedAttribute.name,
                        'match': states.selectedOption.name ? states.selectedOption.name : states.selectedOption,
                        "type": states.checked ? states.checkedValue.name : states.selectedOption.name ? states.selectedOption.name : states.selectedOption,
                        "value": isCharacterType ? states.selectedAttributeFilter ? parseFloat(states.selectedAttributeFilter) : null : (states.selectedAttributeFilter || states.selectedAttributeFilter === false || states.selectedAttributeFilter === 0) ? (states.selectedAttribute?.type === 'number') ? parseFloat(states.selectedAttributeFilter) : states.selectedAttribute?.type === 'decimal' ? parseFloat(states.selectedAttributeFilter) : states.selectedAttributeFilter : states.selectedAttributeFilter
                    }];
                    temp[temp.length - 1] = t;
                    setStates(prevState => ({...prevState, selectedAttributeFilters: arrangeFilter(temp)}));
                } else {
                    let isCharacterType = ((states.checked ? states.checkedValue.name : states.selectedOption.name ? states.selectedOption.name : states.selectedOption) !== (states.selectedOption.name ? states.selectedOption.name : states.selectedOption) && states.selectedAttribute?.type !== 'date');
                    let t = [{
                        "name": states.selectedAttribute.name,
                        'match': states.selectedOption.name ? states.selectedOption.name : states.selectedOption,
                        "type": states.checked ? states.checkedValue.name : states.selectedOption.name ? states.selectedOption.name : states.selectedOption,
                        "value": isCharacterType ? states.selectedAttributeFilter ? parseFloat(states.selectedAttributeFilter) : null : (states.selectedAttributeFilter || states.selectedAttributeFilter === false || states.selectedAttributeFilter === 0) ? (states.selectedAttribute?.type === 'number') ? parseFloat(states.selectedAttributeFilter) : states.selectedAttribute?.type === 'decimal' ? parseFloat(states.selectedAttributeFilter) : states.selectedAttributeFilter : states.selectedAttributeFilter
                    }];
                    let temp = [...states.selectedAttributeFilters, t];
                    setStates(prevState => ({...prevState, selectedAttributeFilters: arrangeFilter(temp)}));
                }
            }
        }
        clearFilterData();
    }

    const [selectedRowKeystoDelete, setSelectedRowKeystoDelete] = useState([]);

    const rowSelection = {
        getCheckboxProps: (record) => ({
            // Column configuration not to be checked
            sku: record.variation_of,
        }),
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeystoDelete(selectedRowKeys);
        },
        onSelect: (record, selected, selectedRows) => {
            // console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {

            // console.log(selected, selectedRows, changeRows);


        },
    }
    const fixedTop = false;
    const [editColumnsVisible, setEditColumnsVisible] = useState(false);

    const handleEditColumnsClick = () => {
        setEditColumnsVisible(true);
    };

    const exportProductsToCsv = async () => {
        const payload = await dispatch(ProductThunk.exportProducts({
            skip: 0,
            limit: 10,
            exportAll: true,
        }));

        if (payload.type === 'product/exportProducts/fulfilled') {
            const csvData = payload.payload; // Get CSV data from the payload

            // Create a Blob from the CSV data
            const blob = new Blob([csvData], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);

            // Create a temporary link to trigger the download
            const a = document.createElement('a');
            a.href = url;
            a.download = 'products.csv'; // Specify the file name for download
            document.body.appendChild(a);
            a.click(); // Trigger the download
            document.body.removeChild(a); // Clean up

            // Show success notification
            Toast.successNotification("Products Exported Successfully");
        } else {
            Toast.errorNotification("Error Exporting Products");
        }
    }



    const deleteFilter = (val, ind) => {
        dispatch(setDefaultView(false));
        dispatch(setFilterViewName(null));
        let temp = [...states.selectedAttributeFilters];
        let t = temp[ind];
        let l = t?.filter(v => (v !== val));
        if (l.length === 0) {
            temp.splice(ind, 1);
        } else {
            temp[ind] = [...l];

        }

        setStates(prevState => ({...prevState, selectedAttributeFilters: [...temp]}));
        clearFilterData();


    }
    const handleEditColumnsClose = () => {
        setEditColumnsVisible(false);
    };

    const handleBulkDeleteProducts = async () => {
        if (selectedRowKeystoDelete.length) {
            const payload = await dispatch(ProductThunk.productBulkDelete(selectedRowKeystoDelete));
            if (payload.type === PRODUCT_BULK_DELETE_FULFILLED) {
                Toast.successNotification(`${selectedRowKeystoDelete.length}  Product Deleted Successfully`);
                dispatch(ProductThunk.productList({
                    skip: skip === 1 ? 0 : (((skip / limit) + 1 === Math.ceil(count / limit)) && selectedRowKeystoDelete.length === products.length) ? skip - limit : skip,
                    limit: limit,
                    filters: states.selectedAttributeFilters,
                    search: states.searchVal,
                    defaultView:defaultView
                }));
                setTableParams({
                    ...tableParams,
                    pagination: {
                        ...tableParams.pagination,
                        current: skip === 1 ? 0 : (skip / limit) + 1,
                        pageSize: limit,
                    },
                })
                setSelectedRowKeystoDelete([]);
            }
        } else {
            Toast.errorNotification("Please Select Product to Delete");
        }

    }


    const handleTableChange = async (pagination) => {
        const newSkip = (pagination.current - 1) * pagination.pageSize;
        const newLimit = pagination.pageSize;
        setStates(prevState => ({...prevState, isPaginationProductLoading: true}));
        // Update local state with new pagination values

        setTableParams({
            ...tableParams,
            pagination: {
                ...tableParams.pagination,
                current: pagination.current,
                pageSize: pagination.pageSize,
            },
        });

        const payload = await dispatch(ProductThunk.productList({
            skip: newSkip,
            limit: newLimit,
            filters:states.selectedAttributeFilters,
            search: states.searchVal,
            defaultView:defaultView
        }));

        if (payload.type === PRODUCT_LIST_FULFILLED) {
            setStates(prevState => ({...prevState, isPaginationProductLoading: false}));
        } else {
            setStates(prevState => ({...prevState, isPaginationProductLoading: false}));
        }

        // Fetch data based on the new pagination values
    };

    const [isDivVisible, setDivVisible] = useState(false);

    const toggleDivVisibility = () => {
        setDivVisible(!isDivVisible);
    };

    const items = [
        {

            key: '1',
            label: (
                <Link to="/product/add">
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                 fill="none">
                              <path d="M1 8H8M8 8H15M8 8V1M8 8V15" stroke="#011952" strokeWidth="2"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span> Add New Product
                </Link>

            ),
        },
        {
            key: '2',
            label: (
                <Link to="/product/import">
                        <span className="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                 fill="none">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M11.78 7.159C11.6394 7.01855 11.4488 6.93966 11.25 6.93966C11.0512 6.93966 10.8606 7.01855 10.72 7.159L8.75 9.129V1.75C8.75 1.55109 8.67098 1.36032 8.53033 1.21967C8.38968 1.07902 8.19891 1 8 1C7.80109 1 7.61032 1.07902 7.46967 1.21967C7.32902 1.36032 7.25 1.55109 7.25 1.75V9.129L5.28 7.159C5.13783 7.02652 4.94978 6.9544 4.75548 6.95783C4.56118 6.96125 4.37579 7.03997 4.23838 7.17738C4.10097 7.31479 4.02225 7.50018 4.01883 7.69448C4.0154 7.88878 4.08752 8.07683 4.22 8.219L7.47 11.469L8 12L8.53 11.47L11.78 8.22C11.8497 8.15035 11.905 8.06765 11.9427 7.97662C11.9804 7.8856 11.9998 7.78803 11.9998 7.6895C11.9998 7.59097 11.9804 7.4934 11.9427 7.40238C11.905 7.31135 11.8497 7.22865 11.78 7.159ZM2.5 9.75C2.5 9.65151 2.4806 9.55398 2.44291 9.46299C2.40522 9.37199 2.34997 9.28931 2.28033 9.21967C2.21069 9.15003 2.12801 9.09478 2.03701 9.05709C1.94602 9.0194 1.84849 9 1.75 9C1.65151 9 1.55398 9.0194 1.46299 9.05709C1.37199 9.09478 1.28931 9.15003 1.21967 9.21967C1.15003 9.28931 1.09478 9.37199 1.05709 9.46299C1.0194 9.55398 1 9.65151 1 9.75V13C1 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15H13C13.5304 15 14.0391 14.7893 14.4142 14.4142C14.7893 14.0391 15 13.5304 15 13V9.75C15 9.55109 14.921 9.36032 14.7803 9.21967C14.6397 9.07902 14.4489 9 14.25 9C14.0511 9 13.8603 9.07902 13.7197 9.21967C13.579 9.36032 13.5 9.55109 13.5 9.75V13C13.5 13.1326 13.4473 13.2598 13.3536 13.3536C13.2598 13.4473 13.1326 13.5 13 13.5H3C2.86739 13.5 2.74021 13.4473 2.64645 13.3536C2.55268 13.2598 2.5 13.1326 2.5 13V9.75Z"
                                  fill="#011952"/>
                            </svg>
                        </span> Import CSV File
                </Link>

            ),
        }
    ];

    const props={
        editColumnsVisible: editColumnsVisible,
        setEditColumnsVisible: setEditColumnsVisible,
        handleEditColumnsClose: handleEditColumnsClose,
        selectedRowKeystoDelete: selectedRowKeystoDelete,
        handleBulkDeleteProducts: handleBulkDeleteProducts,
        handleEditColumnsClick: handleEditColumnsClick,
        handleSearch: handleSearch,
        singleProduct: singleProduct,
        items: items,
        toggleDivVisibility: toggleDivVisibility,
        isDivVisible: isDivVisible,
        isLoading: isLoading,
        count: count,
        isCsvImported: isCsvImported,
        products: products,
        rowSelection: rowSelection,
        fixedTop: fixedTop,
        columns: columns,
        tableParams: tableParams,
        skip: skip,
        limit: limit,
        parentCount: parentCount,
        variantsCount: variantsCount,
        CustomExpandIcon: CustomExpandIcon,
        handleTableChange: handleTableChange,
        handleApplyFilter: handleApplyFilter,
        onSearch: onSearch,
        handleSearchAttribute: handleSearchAttribute,
        handleSetFilter: handleSetFilter,
        clearFilterData: clearFilterData,
        attributes: attributes,
        onSearchEdit: onSearchEdit,
        deleteFilter: deleteFilter,
        handleOpenFilterModal: handleOpenFilterModal,
        handleFilterModalClose: handleFilterModalClose,
        handleSaveFilter: handleSaveFilter,
        handleLoadFilter: handleLoadFilter,
        clearSelectedFilter: clearSelectedFilter,
        defaultView: defaultView,
        toogleDefaultView: toogleDefaultView,
        filterViewName:filterViewName,
        states: states,
        setStates: setStates,
        exportProductsToCsv: exportProductsToCsv,
    }
    return (
        <ProductListWrapper {...props} />
    )
}


export default ProductList;
