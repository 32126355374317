import api from "../../config/api";

const ProductService = {

    getProducts: async () => {
        return await api.get('/products');
    },

    addProduct: async (newProduct) => {
        return await api.post('/product/add', {...newProduct});
    },

    importProductCsv: async (encodedData) => {
        return await api.post('/product/import/dashboard', {
                compressedData: encodedData
            },
            {
                headers: {
                    'Content-Type': 'application/octet-stream'
                }
            }
        );
    },

    productList: async (skip, limit, filters, search, defaultView) => {
        return await api.post('/product/list', {
            skip: skip,
            limit: limit,
            filter: filters ? filters : [],
            search: search || "",
            // defaultView:(defaultView || defaultView===false) ?defaultView:true
            defaultView: false
        });
    },
    productDetail: async ({id, sku}) => {
        if (id) {
            return await api.get(`/product/details?id=${id}`);
        } else {
            return await api.get(`/product/details?sku=${sku}`);
        }
    },

    skuValidation: async (sku, excludeProductId = undefined) => {
        return await api.get(`/sku/validate?sku=${sku}&excludeProductId=${excludeProductId}`);
    },

    variantUnlink: async (variantIdList) => {
        return await api.put(`/variant/unlink`, {variantIdList});
    },
    productDelete: async (id) => {
        return await api.delete(`/product/delete?id=${id}`);
    },
    productEdit: async (product) => {
        return await api.post(`/product/edit`, {...product});
    },
    editColumnsProductList: async (attributeList) => {
        return await api.put(`/update/list/column`, {attributeList});
    },
    productBulkDelete: async (productIdList) => {
        return await api.post(`/product/bulk/delete`, {productIdList});
    },
    productAssetUpload: async (formData) => {
        return await api.post(`/product/asset/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    productAssetDelete: async (body) => {
        return await api.post(`/product/asset/delete`, body);
    },

    productFilterSave: async (filter) => {
        return await api.post(`/product/filter/save`, filter);
    },

    exportProducts: async (filter) => {
        return await api.post(`/product/export`, filter);
    }

};

export default ProductService;