import {Button, Checkbox, Input, Modal, Switch} from "antd";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Toast from "../Notification/Toast";
import ProdctThunk from "../../redux/thunk/ProductThunk";
import {DeleteOutlined} from "@ant-design/icons";
import {toProperCase} from "../../utils/helpers";
import {PRODUCT_SKU_VALIDATION_FULFILLED,ADD_PRODUCT_FULFILLED} from "../../redux/types/ProductActionTypes"

const REQUIRED_ATTRIBUTE=["product_name","taxonomy_categories","brand_name","price","parent_sku"];

const AddVariationForm = ({present, visible, onCancel, getProductDetails}) => {

    const dispatch = useDispatch();
    const {attributes} = useSelector(state => state.attributes);
    const [sku, setSku] = useState("");
    const [label, setLabel] = useState("");
    const [availableProductAttributes, setAvailableProductAttributes] = useState([]);
    const [selectedProductAttributes, setSelectedProductAttributes] = useState([]);
    const [searchAvailable] = useState('');
    const [searchSelected] = useState('');

    const handleInput1Change = (e) => {
        setSku(e.target.value);
    };

    const handleInput2Change = (e) => {
        setLabel(e.target.value);
    };



    useEffect(() => {
        const setAttributes = () => {
            const keysToExclude = ["_id", "id", "sku", "label", "created_at", "updated_at", "children", "is_variant"];
            const keys = Object.keys(present);
            const filteredKeys = keys.filter((key) => !keysToExclude.includes(key));
            const attributes = filteredKeys
                .filter((key) => !REQUIRED_ATTRIBUTE.includes(key)) // filter out required attributes
                .map((key) => ({
                    name: key,
                    value: present[key],
                }));

            setAvailableProductAttributes(attributes);

        };
        setAttributes();
    }, [present, visible]);

    const handleAttributeChange = (checkedValues) => {
        setSelectedProductAttributes(checkedValues);
    };
    const filterAttributes = (attributes, searchValue) => {

        return attributes.filter(attribute => attribute?.toString().toLowerCase().includes(searchValue.toString().toLowerCase()));
    };

    const handleSelectAll = () => {
        const allAttributeNames = availableProductAttributes.map(attribute => attribute.name);
        setSelectedProductAttributes(prevAttributes => {
            if (prevAttributes.length === allAttributeNames.length) {
                return [];
            } else {
                return allAttributeNames;
            }
        });
    };


    const handleRemoveAll = () => {
        setSelectedProductAttributes([]);
    };

    const handleSubmit = async () => {
        if (!sku.length) {
            Toast.errorNotification("Enter Sku Values");
            return;
        }

        const payloadSku = await dispatch(ProdctThunk.productSkuValidation({sku: sku}));

        if (payloadSku.type === PRODUCT_SKU_VALIDATION_FULFILLED) {
            if (!payloadSku.payload.data.isAvailable) {
                Toast.errorNotification("SKU Already Exist");
            } else {
                const attributeTypes={};
                // console.log(attributes)
                attributes.forEach((attribute) => {
                   attributeTypes[attribute.name] = attribute.type;
                })
                const attributesToSend = [
                    {name: "variation_of", value: present.sku,type:"text"},
                    ...[...selectedProductAttributes,...REQUIRED_ATTRIBUTE].map((attributeName) => ({
                        name: attributeName,
                        value: attributeName === "parent_sku"
                            ? present.sku
                            : present[attributeName] ||
                            (["multiselect", "dropdown"].includes(attributeTypes[attributeName]) ? [] : ""),
                        type: attributeTypes[attributeName],

                    })),
                ];

                const payload = await dispatch(
                    ProdctThunk.addProduct({
                        sku,
                        label,
                        attributes: attributesToSend,
                    })
                );

                if (payload.type === ADD_PRODUCT_FULFILLED) {
                    Toast.successNotification("Product Added Successfully");
                    setSku("");
                    setLabel("");
                    onCancel();
                    setSelectedProductAttributes([]);
                    setAvailableProductAttributes([]);
                    getProductDetails();
                } else {
                    Toast.errorNotification(payload.error.message);
                }
            }
        }
    };

    return (
        <Modal
            title="Create New Product"
            open={visible}
            onCancel={() => {
                setSku("");
                setLabel("");
                setSelectedProductAttributes([]);
                setAvailableProductAttributes([]);
                onCancel();
            }}
            width={1800}
            className={"custom_modal variation_modal"}
            footer={[
                <Button className={"secondary_btn"} key="cancel" onClick={() => {
                    setSku("");
                    setLabel("");
                    setSelectedProductAttributes([]);
                    setAvailableProductAttributes([]);
                    onCancel();
                }}>
                    Cancel
                </Button>,
                <Button className={"primary_btn"} key="submit" type="primary" onClick={handleSubmit}>
                    Submit
                </Button>,
            ]}
        >
            <div className={"form_fields"}>
                <div className={"form_group"}>
                    <label htmlFor="sku">SKU:</label>
                    <Input id={'sku'} placeholder="Enter SKU" value={sku} rootClassName={"custom-input-field"}
                           onChange={handleInput1Change}/>
                </div>
                <div className={"form_group"}>
                    <label htmlFor="label">Label:</label>
                    <Input id={'label'} placeholder="Enter Label" value={label} onChange={handleInput2Change}
                           rootClassName={"custom-input-field"}/>
                </div>
            </div>
                <div className={"product_attribute_wrapper"}>
                    <div className={"body_content"}>
                        <div className={"left_col"}>
                            <div className={"top_sec"}>
                                <h3>Available Attributes ({availableProductAttributes?.length})</h3>
                                <p>Select All &nbsp; &nbsp;
                                    <Switch
                                        checked={selectedProductAttributes.length === availableProductAttributes.length}
                                        onChange={handleSelectAll}
                                    />
                                </p>
                            </div>
                            <div className={"inner_listing"}>
                            <Checkbox.Group
                                style={{
                                    width: '100%',
                            }}
                                value={[...selectedProductAttributes,...REQUIRED_ATTRIBUTE]}
                                onChange={handleAttributeChange}
                            >

                                {filterAttributes(availableProductAttributes, searchAvailable).map((attribute) => (
                                    <Checkbox className={"checkbox_card"} key={attribute?.name} value={attribute?.name}>
                                        {toProperCase(attribute?.name)}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                            </div>
                        </div>
                        <div className={"divider"}></div>
                        <div className={"right_col"}>

                            <div className={"top_sec"}>
                            <h3>Selected Attributes ({[...selectedProductAttributes,...REQUIRED_ATTRIBUTE]?.length})</h3>
                                {
                                    !!selectedProductAttributes.length > 0 && ( <Button

                                        type={"danger"}
                                        icon={<DeleteOutlined/>}
                                        onClick={handleRemoveAll}>Remove All</Button>)
                                }
                            </div>
                            <div className={"inner_listing"}>
                                {filterAttributes(REQUIRED_ATTRIBUTE, searchSelected).map((attributeName) => (
                                    <Checkbox className={"checkbox_card"} key={attributeName} value={attributeName}  checked={true}>
                                        {toProperCase(attributeName)}
                                    </Checkbox>
                                ))}
                            <Checkbox.Group
                                style={{
                                    width: '100%',
                                }}
                                value={selectedProductAttributes}
                                onChange={handleAttributeChange}
                            >

                                {filterAttributes(selectedProductAttributes, searchSelected).map((attributeName) => (
                                    <Checkbox className={"checkbox_card"} key={attributeName} value={attributeName}>
                                        {toProperCase(attributeName)}
                                    </Checkbox>
                                ))}
                            </Checkbox.Group>
                            </div>
                        </div>
                    </div>
                </div>

        </Modal>
    );
};


export default AddVariationForm;
